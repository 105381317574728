/** @format */

import React from "react";
import {IconButton} from "@material-ui/core";
import {Link as RouterLink} from "react-router-dom";

// The use of React.forwardRef will no longer be required for react-router-dom v6.
// See https://github.com/ReactTraining/react-router/issues/6056
const Link = React.forwardRef((props, ref) => (
    <RouterLink innerRef={ref} {...props} />
));

const NavIconButton = ({icon, to, color, label}) => {
    return (
        <IconButton color={color} component={Link} to={to} aria-label={label}>
            {icon}
        </IconButton>
    );
};

export default NavIconButton;
