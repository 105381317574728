/** @format */

import React, {useState} from "react";
import ReactDOM from "react-dom";

import GSCMaterial from "@gsc/material";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import locale from "date-fns/locale/en-GB";
import ApolloClient from "apollo-boost";
import {ApolloProvider} from "@apollo/react-hooks";
import {Provider as ReduxProvider} from "react-redux";
import store, {history, persistor} from "./redux/store";
import {ConnectedRouter} from "connected-react-router";
import {PersistGate} from "redux-persist/integration/react";

import registerServiceWorker from "./registerServiceWorker";
import "@gsc/material/ProximaNovaFontFace.css";

import {
    GSCAuthProvider,
    GSCAuthConsumer,
    initialAuthValues,
} from "./components/auth";
import {apolloParams} from "./config";
import App from "./App";
import GSCWrappedLoading from "./components/general/WrappedLoading";

const ConnectedApp = () => {
    const [auth, setAuth] = useState({initialAuthValues, pic: null});
    return (
        <GSCMaterial>
            <GSCAuthProvider value={{auth, setAuth}}>
                <GSCAuthConsumer>
                    {({auth}) => {
                        const clientConfig = {
                            ...apolloParams,
                            headers: {
                                Authorization: `Bearer ${auth.accessToken}`,
                            },
                        };
                        return (
                            <ReduxProvider store={store}>
                                <ApolloProvider
                                    client={new ApolloClient(clientConfig)}
                                >
                                    <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                        locale={locale}
                                    >
                                        <ConnectedRouter history={history}>
                                            <PersistGate
                                                loading={<GSCWrappedLoading />}
                                                persistor={persistor}
                                            >
                                                <App />
                                            </PersistGate>
                                        </ConnectedRouter>
                                    </MuiPickersUtilsProvider>
                                </ApolloProvider>
                            </ReduxProvider>
                        );
                    }}
                </GSCAuthConsumer>
            </GSCAuthProvider>
        </GSCMaterial>
    );
};

ReactDOM.render(<ConnectedApp />, document.getElementById("root"));
registerServiceWorker();
