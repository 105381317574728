/** @format */

import React from "react";
import {Card, Grid, Tabs, Tab, Typography} from "@material-ui/core";
import TimetableNote from "./TimetableNote";
import {getNonEmptyNotes, capitalFirst} from "../../utils/corpEventFilters";
import {notesExcludedFromExternalEvents} from "../../config";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    card: ({border}) => ({
        border: border ? `1px solid ${theme.gsc[border]}` : "none",
    }),
    root: {
        backgroundColor: theme.gsc.white,
    },
    tabs: {
        backgroundColor: theme.gsc.offwhite,
    },
    fullWidth: {
        width: "100%",
    },
    noteWrapper: {
        padding: theme.spacing(2),
    },
    noteContent: {
        whiteSpace: "pre-line",
    },
    noteHidden: {
        display: "none",
    },
    printTitle: {
        padding: theme.spacing(2, 2, 0, 2),
    },
}));

const NotesTabs = ({
    notes,
    startClosed = false,
    allowClosure = false,
    tabsOnBottom = false,
    FirstTab = null,
    firstTabProps = null,
    controlled = false,
    border = false,
    internal = false,
} = {}) => {
    const classes = useStyles({border});
    const [uncontrolledSelection, setUncontrolledSelection] = React.useState(
        startClosed ? false : 0
    );

    const [selected, setSelected] = controlled || [
        uncontrolledSelection,
        setUncontrolledSelection,
    ];
    const handleChange = (event, newValue) =>
        allowClosure && newValue === selected
            ? setSelected(false)
            : setSelected(newValue);

    const nonEmptyNotes = getNonEmptyNotes(notes);
    const notesToDisplay = internal
        ? nonEmptyNotes
        : nonEmptyNotes.filter(
              ([key, value]) => !notesExcludedFromExternalEvents.includes(key)
          );

    return (
        <Card className={classes.card}>
            <Grid
                container
                direction={tabsOnBottom ? "column-reverse" : "column"}
                justify="flex-start"
                alignItems="flex-start"
                className={classes.root}
            >
                {notesToDisplay.length === 0 && !FirstTab ? (
                    <Grid item xs={12} className={classes.fullWidth}>
                        <Typography
                            variant="body1"
                            className={classes.noteWrapper}
                        >
                            There are no notes on this event.
                        </Typography>
                    </Grid>
                ) : (
                    <>
                        <Grid item xs={12} className={classes.fullWidth}>
                            <Tabs
                                variant="scrollable"
                                scrollButtons="on"
                                value={selected}
                                onChange={handleChange}
                                aria-label="notes categories"
                                className={classes.tabs}
                            >
                                {firstTabProps && (
                                    <Tab
                                        label={capitalFirst(
                                            firstTabProps.label
                                        )}
                                    />
                                )}
                                {notesToDisplay.map(([key, value]) => (
                                    <Tab key={key} label={capitalFirst(key)} />
                                ))}
                            </Tabs>
                        </Grid>

                        {FirstTab && selected === 0 && (
                            <FirstTab {...firstTabProps} />
                        )}

                        {notesToDisplay.map(([key, value], idx) => (
                            <Grid
                                key={key}
                                item
                                xs={12}
                                container
                                direction="column"
                                justify="flex-start"
                                alignItems="flex-start"
                                role="tabpanel"
                                className={
                                    selected === (FirstTab ? idx + 1 : idx)
                                        ? classes.noteWrapper
                                        : classes.noteHidden
                                }
                            >
                                {key === "timetable" ? (
                                    <TimetableNote note={value} />
                                ) : (
                                    <Typography
                                        variant="body1"
                                        className={classes.noteContent}
                                    >
                                        {value}
                                    </Typography>
                                )}
                            </Grid>
                        ))}
                    </>
                )}
            </Grid>
        </Card>
    );
};

export default NotesTabs;
