/** @format */

import React from "react";
import GSCNavBar from "./components/navbar/";
import GlobalAlert from "./components/general/GlobalAlert";
import DateRoute from "./routes/DateRoute";
import NumbersRoute from "./routes/NumbersRoute/";
import EventsRoute from "./routes/EventsRoute";
import CorporateRoute from "./routes/CorporateRoute";
import AuthRoute from "./routes/AuthRoute";
import RouteMapper from "./routes/RouteMapper";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    toolbar: theme.mixins.toolbar,
}));

const routes = [
    {
        path: "/date",
        render: ({match}) => <DateRoute match={match} />,
    },
    {
        path: "/numbers",
        render: ({match}) => <NumbersRoute match={match} />,
    },
    {
        path: "/events",
        render: ({match}) => <EventsRoute match={match} />,
    },
    {
        path: "/corporate",
        render: ({match}) => <CorporateRoute match={match} />,
    },
    {
        path: "/auth",
        render: ({match}) => <AuthRoute match={match} />,
    },
];

const App = () => {
    const classes = useStyles();
    // There's an "extra" div in here as a simple way to make room for the NavBar
    return (
        <>
            <GSCNavBar />
            <div className={classes.toolbar} />
            <GlobalAlert />
            <RouteMapper routes={routes} defaultRoute="/date" />
        </>
    );
};

export default App;
