/** @format */

import React from "react";
import Spinner from "react-spinkit";

const GSCLoading = () => (
    <Spinner
        name="wandering-cubes"
        fadeIn="none"
        color="#30b7bb"
        className="mx-auto my-4 text-center"
    />
);

export default GSCLoading;
