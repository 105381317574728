/** @format */

import React from "react";
import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
} from "@material-ui/core";
import StatusAvatar from "./StatusAvatar";
import {useDispatch} from "react-redux";
import {push} from "connected-react-router";
import {getEventSpaces} from "../../utils/corpEventFilters";
import {format, isPast} from "date-fns";
import {makeStyles} from "@material-ui/core/styles";
import {corpEventIsInternal} from "../../config";

const useStyles = makeStyles((theme) => ({
    paper: {
        width: "100%",
        border: `1px solid ${theme.gsc.purple}`,
    },
    tableWrapper: {
        overflowX: "auto",
    },
    table: {
        minWidth: 750,
    },
    headerRow: {
        backgroundColor: theme.gsc.purple,
    },
    headerText: {
        "color": theme.gsc.white,
        "&:hover": {
            "color": theme.gsc.white,
            "& $headerIcon": {
                opacity: 1,
                color: theme.gsc.white,
            },
        },
        "&$headerTextActive": {
            "color": theme.gsc.white,
            // && instead of & is a workaround for https://github.com/cssinjs/jss/issues/1045
            "&& $headerIcon": {
                opacity: 1,
                color: theme.gsc.white,
            },
        },
    },
    headerTextActive: {},
    headerIcon: {},
    row: {
        cursor: "pointer",
    },
    pastEventRow: {
        cursor: "pointer",
        backgroundColor: theme.gsc.offwhite,
    },
    eventName: {
        fontWeight: "bold",
    },
    restrictedWidthColumn: {
        maxWidth: 300,
    },
}));

const desc = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
};

const getSorting = (order, orderBy) => {
    return order === "desc"
        ? (a, b) => desc(a, b, orderBy)
        : (a, b) => -desc(a, b, orderBy);
};

const stableSort = (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
};

const headCells = [
    {id: "masterBookId", label: "Booking"},
    {id: "eventName", label: "Event Title"},
    {id: "eventStart", label: "Start"},
    {id: "eventEnd", label: "End"},
    {id: "eventType", label: "Event Type"},
    {id: "eventSpaces", label: "Spaces"},
    {id: "eventManager", label: "Event Manager"},
    {id: "eventAttendees", label: "Attendees"},
];

const EnhancedTableHead = ({classes, order, orderBy, onRequestSort}) => {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    return (
        <TableHead>
            <TableRow className={classes.headerRow}>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align="left"
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={order}
                            onClick={createSortHandler(headCell.id)}
                            classes={{
                                root: classes.headerText,
                                active: classes.headerTextActive,
                                icon: classes.headerIcon,
                            }}
                        >
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

const OverviewTable = ({events}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("eventStart");
    const handleRequestSort = (event, property) => {
        const isOrderedByProperty = orderBy === property;
        const isDesc = order === "desc";
        isOrderedByProperty && setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    };

    const displayFormat = (date) => format(date, "EEE do MMM yyyy, HH:mm");

    return (
        <Paper className={classes.paper}>
            <div className={classes.tableWrapper}>
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size="medium"
                    aria-label="enhanced table"
                >
                    <EnhancedTableHead
                        classes={classes}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                        {stableSort(events, getSorting(order, orderBy)).map(
                            (event) => {
                                const eventIsInThePast = isPast(
                                    Number(event.eventEnd)
                                );
                                const eventSpaces = getEventSpaces(event);
                                return (
                                    <TableRow
                                        hover
                                        onClick={() =>
                                            dispatch(
                                                push(
                                                    `/corporate/event/${event.masterBookId}`
                                                )
                                            )
                                        }
                                        tabIndex={-1}
                                        key={event.masterBookId}
                                        className={
                                            eventIsInThePast
                                                ? classes.pastEventRow
                                                : classes.row
                                        }
                                    >
                                        <TableCell>
                                            <Grid
                                                container
                                                direction="row"
                                                justify="flex-start"
                                                alignItems="center"
                                            >
                                                <StatusAvatar
                                                    status={event.bookingStatus}
                                                    internal={corpEventIsInternal(
                                                        event.resourceName
                                                    )}
                                                    padded
                                                    tooltip
                                                />
                                                {event.masterBookId}
                                            </Grid>
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            className={classes.eventName}
                                        >
                                            {event.eventName || "—"}
                                        </TableCell>
                                        <TableCell>
                                            {displayFormat(
                                                Number(event.eventStart)
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {displayFormat(
                                                Number(event.eventEnd)
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {event.eventType || "—"}
                                        </TableCell>
                                        <TableCell
                                            className={
                                                classes.restrictedWidthColumn
                                            }
                                        >
                                            {eventSpaces}
                                        </TableCell>
                                        <TableCell>
                                            {event.eventManager || "—"}
                                        </TableCell>
                                        <TableCell>
                                            {event.eventAttendees || "—"}
                                        </TableCell>
                                    </TableRow>
                                );
                            }
                        )}
                    </TableBody>
                </Table>
            </div>
        </Paper>
    );
};

export default OverviewTable;
