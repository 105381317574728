/** @format */

import React from "react";
import RouteMapper from "./RouteMapper";
import AuthDetails from "../components/auth/AuthDetails";
import AuthLogin from "../components/auth/AuthLogin";
import AuthLogout from "../components/auth/AuthLogout";

const routes = [
    {
        path: "/details",
        render: () => <AuthDetails />,
    },
    {
        path: "/login",
        render: () => <AuthLogin doLogin />,
    },
    {
        path: "/auto",
        render: () => <AuthLogin doLogin auto />,
    },
    {
        path: "/logout",
        render: () => <AuthLogout doLogout />,
    },
    {
        path: "/return",
        render: () => <AuthLogin />,
    },
];

const AuthRoute = ({match}) => (
    <RouteMapper
        routes={routes}
        rootPath={match.path}
        defaultRoute="/details"
    />
);

export default AuthRoute;
