/** @format */

import React from "react";
import gql from "graphql-tag";
import {useMutation} from "@apollo/react-hooks";
import {apiFormat} from "../../config";
import {
    Container,
    Grid,
    Typography,
    Button,
    Checkbox,
    FormControlLabel,
    TextField,
    IconButton,
    Dialog,
    DialogTitle,
    DialogActions,
} from "@material-ui/core";
import {DatePicker} from "@material-ui/pickers";
import OverviewList from "../corporateEvent/OverviewList";
import {
    format,
    startOfMonth,
    endOfMonth,
    addMonths,
    subMonths,
    parseISO,
} from "date-fns";
import LinkIcon from "@material-ui/icons/Link";
import SyncIcon from "@material-ui/icons/Sync";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {makeStyles} from "@material-ui/core/styles";
import {useSelector, useDispatch} from "react-redux";
import useReset from "../../utils/useReset";
import {push} from "connected-react-router";
import {
    corpDateRangeSelector,
    corpDateRangeActions,
} from "../../redux/corpDateRangeSlice";
import {
    corpStatusFilterSelector,
    corpStatusFilterActions,
} from "../../redux/corpStatusFilterSlice";
import {
    corpResourceFilterSelector,
    corpResourceFilterActions,
} from "../../redux/corpResourceFilterSlice";
import {
    datePickerPastStart,
    datePickerFutureEnd,
    appRootUri,
    updateRangeMax,
} from "../../config";

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        margin: theme.spacing(1, 0),
        padding: theme.spacing(1),
        [theme.breakpoints.up("md")]: {
            padding: theme.spacing(2, 4),
        },
    },
    itemContainer: {
        width: "100%",
    },
    headingContainer: {
        marginBottom: theme.spacing(1),
        [theme.breakpoints.up("md")]: {
            marginBottom: theme.spacing(1),
        },
    },
    datePicker: {
        margin: theme.spacing(1),
        [theme.breakpoints.up("md")]: {
            margin: theme.spacing(2),
        },
    },
    buttonContainer: {
        padding: theme.spacing(0, 1),
    },
    dateButton: {
        margin: theme.spacing(1),
    },
    listContainer: {
        width: "100%",
        margin: theme.spacing(1, 0),
        padding: theme.spacing(0),
        [theme.breakpoints.up("md")]: {
            margin: theme.spacing(2, 0),
            padding: theme.spacing(0, 2),
        },
    },
    switchContainer: {
        paddingLeft: theme.spacing(2),
    },
    confirmedCheckbox: {
        "color": theme.gsc.green,
        "&$checkedCheckbox": {
            color: theme.gsc.green,
        },
    },
    provisionalCheckbox: {
        "color": theme.gsc.orange,
        "&$checkedCheckbox": {
            color: theme.gsc.orange,
        },
    },
    cancelledCheckbox: {
        "color": theme.gsc.red,
        "&$checkedCheckbox": {
            color: theme.gsc.red,
        },
    },
    resourceCheckbox: {
        "color": theme.gsc.purple,
        "&$checkedCheckbox": {
            color: theme.gsc.purple,
        },
    },
    checkedCheckbox: {},
    filterTitle: {
        fontWeight: "bold",
        paddingRight: theme.spacing(2),
    },
    linkDialog: {
        padding: theme.spacing(2),
    },
    linkTextField: {
        padding: theme.spacing(0, 3, 2, 3),
    },
}));

const LinkDialog = ({start, end, open, setOpen}) => {
    const classes = useStyles();
    const defaultCopyText = "Copy";
    const [copyText, setCopyText] = React.useState(defaultCopyText);
    const textFieldRef = React.useRef(null);

    const startDisplay = format(start, "yyyy-MM-dd");
    const endDisplay = format(end, "yyyy-MM-dd");

    const doCopy = () => {
        textFieldRef.current.select();
        document.execCommand("copy");
        setCopyText("Copied!");
    };
    useReset(setCopyText, defaultCopyText, copyText, 3000);

    return (
        <Dialog
            fullWidth
            open={open}
            onClose={() => setOpen(false)}
            classes={{paper: classes.linkDialog}}
        >
            <DialogTitle>Link to these dates</DialogTitle>
            <TextField
                label={undefined}
                variant="outlined"
                fullWidth
                value={`${appRootUri}/corporate/list/${startDisplay}/${endDisplay}`}
                inputRef={textFieldRef}
                classes={{root: classes.linkTextField}}
            />
            <DialogActions>
                <Button
                    variant="text"
                    color="secondary"
                    onClick={() => setOpen(false)}
                >
                    Close
                </Button>
                {document.queryCommandSupported("copy") && (
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={doCopy}
                    >
                        {copyText}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

const CorporateList = ({forceStart, forceEnd}) => {
    const classes = useStyles();
    const isSmallScreen = useMediaQuery((theme) =>
        theme.breakpoints.down("sm")
    );
    const isMediumScreen = useMediaQuery((theme) =>
        theme.breakpoints.down("md")
    );

    const dispatch = useDispatch();
    const {start, end} = useSelector(corpDateRangeSelector);

    // Set update button visibility
    const rangeSize = end - start;
    const updateRangeButton =
        start < end && rangeSize <= updateRangeMax ? (
            <IconButton color="secondary" onClick={() => corpEventUpdate()}>
                <SyncIcon />
            </IconButton>
        ) : null;

    const startDateForUpdate = apiFormat(new Date(Number(start)));
    const endDateForUpdate = apiFormat(new Date(Number(end)));

    const SYNC_EVENTS = gql`
        mutation ($startDate: String, $endDate: String) {
            syncCorporateEventsFromGammaDb(
                startDate: $startDate
                endDate: $endDate
            ) {
                masterBookId
                lastSync
                eventName
                bookingStatus
                spaces {
                    linkedBookId
                    spaceName
                    area
                }
            }
        }
    `;

    const [triggerEventsSync, {error: mutationError}] =
        useMutation(SYNC_EVENTS);

    const corpEventUpdate = () => {
        triggerEventsSync({
            variables: {
                startDate: startDateForUpdate,
                endDate: endDateForUpdate,
            },
        });

        if (mutationError) {
            console.error("Error updating:", mutationError);
        }
    };

    const setStart = (date) =>
        dispatch({type: corpDateRangeActions.changeStart, payload: date});
    const setEnd = (date) =>
        dispatch({type: corpDateRangeActions.changeEnd, payload: date});

    const setMonthDates = (date) => {
        setStart(startOfMonth(date));
        setEnd(endOfMonth(date));
    };

    const statusDisplay = useSelector(corpStatusFilterSelector);
    const resourceDisplay = useSelector(corpResourceFilterSelector);

    if (forceStart && forceEnd) {
        const newStart = parseISO(forceStart);
        const newEnd = parseISO(forceEnd);
        setStart(newStart);
        setEnd(newEnd);
        dispatch(push("/corporate/list"));
    }
    const [linkDialogOpen, setLinkDialogOpen] = React.useState(false);

    return (
        <Container maxWidth="xl" className={classes.gridContainer}>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                className={classes.itemContainer}
            >
                <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    className={classes.headingContainer}
                >
                    <Typography variant="h4" component="h2">
                        Corporate Events
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                >
                    <Grid
                        item
                        xs={12}
                        md={7}
                        lg={6}
                        xl={4}
                        container
                        direction={isSmallScreen ? "column" : "row"}
                        justify="flex-start"
                        alignItems="center"
                    >
                        <DatePicker
                            variant={isSmallScreen ? "dialog" : "inline"}
                            autoOk
                            inputVariant="outlined"
                            label="Date from"
                            value={start}
                            format="d MMMM yyyy"
                            onChange={setStart}
                            minDate={startOfMonth(datePickerPastStart)}
                            maxDate={endOfMonth(datePickerFutureEnd)}
                            className={classes.datePicker}
                        />
                        <DatePicker
                            variant={isSmallScreen ? "dialog" : "inline"}
                            autoOk
                            inputVariant="outlined"
                            label="Date to"
                            value={end}
                            format="d MMMM yyyy"
                            onChange={setEnd}
                            minDate={startOfMonth(datePickerPastStart)}
                            maxDate={endOfMonth(datePickerFutureEnd)}
                            className={classes.datePicker}
                        />
                        <IconButton
                            color="secondary"
                            onClick={() => setLinkDialogOpen(true)}
                        >
                            <LinkIcon />
                        </IconButton>
                        {updateRangeButton}
                        <LinkDialog
                            start={start}
                            end={end}
                            open={linkDialogOpen}
                            setOpen={setLinkDialogOpen}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={5}
                        lg={6}
                        xl={4}
                        container
                        direction="row"
                        justify={isSmallScreen ? "center" : "flex-end"}
                        alignItems="center"
                        className={classes.buttonContainer}
                    >
                        {!isMediumScreen && (
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() =>
                                    setMonthDates(subMonths(new Date(), 1))
                                }
                                className={classes.dateButton}
                            >
                                Last Month
                            </Button>
                        )}
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => setMonthDates(new Date())}
                            className={classes.dateButton}
                        >
                            This Month
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() =>
                                setMonthDates(addMonths(new Date(), 1))
                            }
                            className={classes.dateButton}
                        >
                            Next Month
                        </Button>
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                >
                    <Grid
                        item
                        xs={12}
                        md={6}
                        container
                        direction="row"
                        justify={isSmallScreen ? "center" : "flex-start"}
                        alignItems="center"
                        className={classes.switchContainer}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={statusDisplay.confirmed}
                                    onChange={() =>
                                        dispatch({
                                            type: corpStatusFilterActions.toggleConfirmed,
                                        })
                                    }
                                    classes={{
                                        root: classes.confirmedCheckbox,
                                        checked: classes.checkedCheckbox,
                                    }}
                                />
                            }
                            label="Confirmed"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={statusDisplay.provisional}
                                    onChange={() =>
                                        dispatch({
                                            type: corpStatusFilterActions.toggleProvisional,
                                        })
                                    }
                                    classes={{
                                        root: classes.provisionalCheckbox,
                                        checked: classes.checkedCheckbox,
                                    }}
                                />
                            }
                            label="Provisional"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={statusDisplay.cancelled}
                                    onChange={() =>
                                        dispatch({
                                            type: corpStatusFilterActions.toggleCancelled,
                                        })
                                    }
                                    classes={{
                                        root: classes.cancelledCheckbox,
                                        checked: classes.checkedCheckbox,
                                    }}
                                />
                            }
                            label="Cancelled"
                            labelPlacement="end"
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        container
                        direction="row"
                        justify={isSmallScreen ? "center" : "flex-end"}
                        alignItems="center"
                        className={classes.switchContainer}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={resourceDisplay.external}
                                    onChange={() =>
                                        dispatch({
                                            type: corpResourceFilterActions.toggleExternal,
                                        })
                                    }
                                    classes={{
                                        root: classes.resourceCheckbox,
                                        checked: classes.checkedCheckbox,
                                    }}
                                />
                            }
                            label="External Events"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={resourceDisplay.internal}
                                    onChange={() =>
                                        dispatch({
                                            type: corpResourceFilterActions.toggleInternal,
                                        })
                                    }
                                    classes={{
                                        root: classes.resourceCheckbox,
                                        checked: classes.checkedCheckbox,
                                    }}
                                />
                            }
                            label="Internal Events"
                            labelPlacement="end"
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.listContainer}>
                    <OverviewList
                        start={start}
                        end={end}
                        statusDisplay={statusDisplay}
                        resourceDisplay={resourceDisplay}
                    />
                </Grid>
            </Grid>
        </Container>
    );
};

export default CorporateList;
