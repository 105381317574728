/** @format */

export const corpResourceFilterSelector = (state) => {
    return {
        external: state.corpResourceFilter.external,
        internal: state.corpResourceFilter.internal,
    };
};

export const corpResourceFilterActions = {
    toggleExternal: "corp/resourceFilterExternalToggle",
    toggleInternal: "corp/resourceFilterInternalToggle",
};

const corpResourceFilterReducer = (
    state = {external: true, internal: true},
    action
) => {
    switch (action.type) {
        case corpResourceFilterActions.toggleExternal: {
            return {...state, external: !state.external};
        }
        case corpResourceFilterActions.toggleInternal: {
            return {...state, internal: !state.internal};
        }
        default:
            return state;
    }
};

export default corpResourceFilterReducer;
