/** @format */

import React from "react";
import {Document, Page, StyleSheet, Font, Text} from "@react-pdf/renderer";
import PageHeader from "./PageHeader";
import TimetableTable from "./TimetableTable";
import SpacesTable from "./SpacesTable";
import SpaceNotes from "./SpaceNotes";
import GeneralNotes from "./GeneralNotes";
import PageFooter from "./PageFooter";
import HorizontalRule from "./HorizontalRule";
import {format} from "date-fns";
import {getNonEmptyNotes} from "../../../utils/corpEventFilters";
import {corpEventIsInternal} from "../../../config";

Font.register({
    family: "ProximaNova",
    src: "https://style.gsc.org.uk/fonts/ProximaNova-Bold.ttf",
});
Font.register({
    family: "Arial",
    fonts: [
        {src: "https://style.gsc.org.uk/fonts/Arial/ArialMT.ttf"},
        {
            src: "https://style.gsc.org.uk/fonts/Arial/Arial-BoldMT.ttf",
            fontWeight: "bold",
        },
        {
            src: "https://style.gsc.org.uk/fonts/Arial/Arial-ItalicMT.ttf",
            fontStyle: "italic",
        },
        {
            src: "https://style.gsc.org.uk/fonts/Arial/Arial-BoldItalicMT.ttf",
            fontWeight: "bold",
            fontStyle: "italic",
        },
    ],
});

const styles = StyleSheet.create({
    page: {
        flexDirection: "column",
        backgroundColor: "#ffffff",
        paddingTop: 5,
        paddingLeft: 10,
        paddingRight: 5,
        paddingBottom: 70,
    },
    oneTimeTitle: {
        fontFamily: "ProximaNova",
        color: "#2e2d44",
        margin: 6,
        fontSize: "24pt",
    },
    timeSubtitle: {
        margin: "1 6 10 6",
        fontFamily: "Arial",
        fontSize: "12pt",
    },
});

const EventsDocument = ({events, includedNoteTypes}) => (
    <Document>
        {events.map((event) => {
            const nonEmptyNotes = Object.fromEntries(
                getNonEmptyNotes(event.eventNotes)
            );
            const start = format(
                Number(event.eventStart),
                "EEEE do MMMM yyyy, HH:mm"
            );
            const end = format(
                Number(event.eventEnd),
                "EEEE do MMMM yyyy, HH:mm"
            );
            const internal = corpEventIsInternal(event.resourceName);
            return (
                <React.Fragment key={event.masterBookId}>
                    <Page size="A4" style={styles.page}>
                        <PageHeader event={event} />
                        <Text style={styles.oneTimeTitle}>
                            {event.eventName}
                        </Text>
                        <Text style={styles.timeSubtitle}>
                            {start} – {end}
                        </Text>
                        <TimetableTable note={nonEmptyNotes.timetable} />
                        <SpacesTable event={event} />
                        <PageFooter event={event} />
                    </Page>
                    <Page size="A4" style={styles.page}>
                        <PageHeader event={event} />
                        <SpaceNotes
                            spaces={event.spaces}
                            internal={internal}
                            includedNoteTypes={includedNoteTypes}
                        />
                        <HorizontalRule />
                        <GeneralNotes
                            notes={event.eventNotes}
                            internal={internal}
                            includedNoteTypes={{
                                ...includedNoteTypes,
                                timetable: false,
                            }}
                        />
                        <PageFooter event={event} />
                    </Page>
                </React.Fragment>
            );
        })}
    </Document>
);

export default EventsDocument;
