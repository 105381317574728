/** @format */

import {eachDayOfInterval, isSameDay} from "date-fns";
import {getCorpEventStatusCategory, corpEventIsInternal} from "../config";

export const filterByStatusDisplay = (statusDisplay) => (event) => {
    const eventBookingStatus = getCorpEventStatusCategory(event.bookingStatus);
    if (!eventBookingStatus) return true;
    const statusesToShow = Object.keys(statusDisplay).filter(
        (status) => statusDisplay[status]
    );
    return statusesToShow.includes(eventBookingStatus);
};

export const filterByResourceDisplay = (resourceDisplay) => (event) => {
    const eventIsInternal = corpEventIsInternal(event.resourceName);
    const eventResourceStatus = eventIsInternal ? "internal" : "external";
    const resourcesToShow = Object.keys(resourceDisplay).filter(
        (resource) => resourceDisplay[resource]
    );
    return resourcesToShow.includes(eventResourceStatus);
};

export const getCorpEventType = (event) =>
    event.bookingCategory && event.eventType
        ? `${event.bookingCategory.trim()}\u2002|\u2002${event.eventType.trim()}`
        : event.bookingCategory
        ? event.bookingCategory.trim()
        : event.eventType
        ? event.eventType.trim()
        : null;

export const getEventSpacesList = (event) =>
    event.spaces?.length
        ? [...new Set(event.spaces.map((space) => space.area.trim()))]
        : null;

export const getEventSpaces = (event) =>
    event.spaces?.length
        ? getEventSpacesList(event).reduce(
              (accumulator, area, idx) =>
                  idx === 0 ? accumulator + area : accumulator + "; " + area,
              ""
          )
        : null;

export const formatTimetableNote = (note) => {
    const noteLines = note.split("\r\n").filter((line) => Boolean(line));
    const fields = noteLines.map((line) =>
        line.split(/[\s]{3,}|[\t]+/).filter((field) => Boolean(field))
    );

    const singleIndexes = fields.reduce(
        (accumulator, line, idx) =>
            line.length === 1 ? [...accumulator, idx] : [...accumulator],
        []
    );
    if (singleIndexes.length === 0) return {fields};

    const tables = singleIndexes.map((singleIndex, idx) => {
        if (idx === singleIndexes.length - 1)
            return fields.slice(singleIndex, fields.length);
        return fields.slice(singleIndex, singleIndexes[idx + 1]);
    });

    return {tables};
};

export const sortByEventStartTimeAsc = (A, B) => A.eventStart - B.eventStart;
export const sortBySpaceStartTimeAsc = (A, B) => A.spaceStart - B.spaceStart;

export const getEventSpacesByDate = (event) => {
    const eventDates = eachDayOfInterval({
        start: Number(event.eventStart),
        end: Number(event.eventEnd),
    });
    const timeSortedSpaces = event.spaces.sort(sortBySpaceStartTimeAsc);
    const eventSpacesByDate = eventDates.map((eventDate) =>
        timeSortedSpaces.filter((space) =>
            isSameDay(eventDate, Number(space.spaceStart))
        )
    );
    return {eventDates, eventSpacesByDate};
};

export const getNonEmptyNotes = (notes) =>
    Object.entries(notes).filter(
        ([key, value]) =>
            !key.startsWith("__") &&
            value &&
            !/^\s*$/.test(
                String(value)
                    .replace(/\\r\\n/, "")
                    .trim()
            )
    );

export const getAllSpaceNoteTypes = (spaces) =>
    Array.from(
        new Set(
            spaces.reduce((accumulator, space) => {
                const nonEmptyNotes = getNonEmptyNotes(space.spaceNotes);
                const nonEmptyNotesCategories = nonEmptyNotes.map(
                    ([key, value]) => key
                );
                return [...accumulator, ...nonEmptyNotesCategories];
            }, [])
        )
    );

export const capitalFirst = (str) =>
    str.length < 3
        ? str.toUpperCase()
        : str.substr(0, 1).toUpperCase() + str.substr(1).toLowerCase();
