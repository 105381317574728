/** @format */

import React from "react";
import {Card, Grid, Tabs, Tab, Button} from "@material-ui/core";
import {
    GSCTabTotal,
    GSCTabPrebooked,
    GSCTabEducation,
    GSCTabCommunity,
    GSCTabMembers,
} from "./Tabs";
import RefetchButton from "../general/RefetchButton";
import CollapseIcon from "@material-ui/icons/ExpandLess";
import ExpandIcon from "@material-ui/icons/ExpandMore";
import LastUpdatedTooltip from "../general/LastUpdatedTooltip";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    card: {
        border: `1px solid ${theme.gsc.navy}`,
    },
    tabsContainer: {
        width: "100%",
        backgroundColor: theme.gsc.offwhite,
    },
    tabContent: {
        width: "100%",
        padding: theme.spacing(4, 1, 2, 1),
    },
    tabIndicator: {
        top: 0,
        bottom: "unset",
    },
    detailsContainer: {
        [theme.breakpoints.down("sm")]: {
            order: -1,
        },
        [theme.breakpoints.down("xs")]: {
            marginBottom: theme.spacing(2),
        },
    },
    updateContainer: {
        padding: theme.spacing(0.5, 2),
        backgroundColor: theme.gsc.white,
    },
}));

const orZero = (number) => (number ? Number(number) : 0);

const truthyStats = (stats) =>
    stats.filter((stat) => Boolean(stat)).length !== 0;

const GSCVisitorNumbersCard = ({
    total,
    adults,
    children,
    sales,
    date,
    phone,
    online,
    education,
    community,
    members,
    lastMemberSync,
    lastSync,
    refetch,
}) => {
    const classes = useStyles();
    const [activeTab, setActiveTab] = React.useState(0);
    const [expanded, setExpanded] = React.useState({
        total: false,
        members: false,
    });
    const toggle = (event, newTab) =>
        newTab !== activeTab && setActiveTab(newTab);

    const activeTabLabel =
        activeTab === 0 ? "total" : activeTab === 4 ? "members" : false;
    const handleExpandClick = () =>
        setExpanded({...expanded, [activeTabLabel]: !expanded[activeTabLabel]});

    const showExpansionControl =
        activeTab === 0
            ? truthyStats([adults, children, sales])
            : activeTab === 4
            ? truthyStats(Object.values(members))
            : false;

    return (
        <Card variant="outlined" className={classes.card}>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
            >
                <Grid
                    item
                    xs={12}
                    hidden={activeTab !== 0}
                    className={classes.tabContent}
                >
                    <GSCTabTotal
                        totalToDisplay={orZero(total)}
                        adults={adults}
                        children={children}
                        sales={sales}
                        date={date}
                        expanded={expanded.total}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    hidden={activeTab !== 1}
                    className={classes.tabContent}
                >
                    <GSCTabPrebooked
                        totalPhone={orZero(phone)}
                        totalOnline={orZero(online)}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    hidden={activeTab !== 2}
                    className={classes.tabContent}
                >
                    <GSCTabEducation totalEducation={orZero(education)} />
                </Grid>
                <Grid
                    item
                    xs={12}
                    hidden={activeTab !== 3}
                    className={classes.tabContent}
                >
                    <GSCTabCommunity
                        date={date}
                        totalCommunity={orZero(community)}
                        communityMembers={orZero(members.community)}
                    />
                </Grid>
                {Boolean(members.total) && (
                    <Grid
                        item
                        xs={12}
                        hidden={activeTab !== 4}
                        className={classes.tabContent}
                    >
                        <GSCTabMembers
                            date={date}
                            members={members}
                            totalMembers={orZero(members.total)}
                            expanded={expanded.members}
                        />
                    </Grid>
                )}

                <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    className={classes.updateContainer}
                >
                    <Grid
                        item
                        xs={9}
                        md={4}
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                    >
                        <LastUpdatedTooltip
                            lastSync={
                                activeTab === 4 ? lastMemberSync : lastSync
                            }
                        />
                    </Grid>
                    {showExpansionControl && (
                        <Grid
                            item
                            xs={12}
                            md={4}
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            className={classes.detailsContainer}
                        >
                            <Button
                                variant="text"
                                color="secondary"
                                onClick={handleExpandClick}
                                endIcon={
                                    expanded[activeTabLabel] ? (
                                        <CollapseIcon />
                                    ) : (
                                        <ExpandIcon />
                                    )
                                }
                            >
                                {expanded[activeTabLabel] ? "Hide" : "More"}{" "}
                                Details
                            </Button>
                        </Grid>
                    )}
                    <Grid
                        item
                        xs={3}
                        md={4}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                    >
                        <RefetchButton onClick={refetch} />
                    </Grid>
                </Grid>

                <Grid
                    item
                    xs={12}
                    container
                    justify="center"
                    className={classes.tabsContainer}
                >
                    <Tabs
                        variant="scrollable"
                        scrollButtons="on"
                        value={activeTab}
                        onChange={toggle}
                        aria-label="visitor number categories"
                        classes={{indicator: classes.tabIndicator}}
                    >
                        <Tab label="Total" />
                        <Tab label="Prebooked" />
                        <Tab label="Education" />
                        <Tab label="Community" />
                        {members.total && <Tab label="Members" />}
                    </Tabs>
                </Grid>
            </Grid>
        </Card>
    );
};

export default GSCVisitorNumbersCard;
