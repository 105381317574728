/** @format */

import React from "react";
import {Container, Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        margin: theme.spacing(1, 0),
        padding: theme.spacing(1),
        [theme.breakpoints.up("md")]: {
            padding: theme.spacing(2, 4),
        },
    },
    itemContainer: {
        width: "100%",
    },
    headingContainer: {
        marginBottom: theme.spacing(1),
        [theme.breakpoints.up("md")]: {
            marginBottom: theme.spacing(1),
        },
    },
    listContainer: {
        width: "100%",
        margin: theme.spacing(1, 0),
        padding: theme.spacing(0),
        [theme.breakpoints.up("md")]: {
            margin: theme.spacing(2, 0),
            padding: theme.spacing(0, 2),
        },
    },
}));

const EventsList = () => {
    const classes = useStyles();
    return (
        <Container maxWidth="xl" className={classes.gridContainer}>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                className={classes.itemContainer}
            >
                <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    className={classes.headingContainer}
                >
                    <Typography variant="h4" component="h2">
                        Events
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="center"
                    className={classes.listContainer}
                >
                    <Typography variant="h6">Coming Soon:</Typography>
                    <Typography variant="body1">
                        Details and sales history for specific events
                    </Typography>
                    <Typography variant="body1">
                        Advance feedback and/or requests welcome!
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    );
};

export default EventsList;
