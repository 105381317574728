/** @format */

export const corpStatusFilterSelector = (state) => {
    // Note that hidden is always false - see config for what counts as "hidden"
    return {
        confirmed: state.corpStatusFilter.confirmed,
        provisional: state.corpStatusFilter.provisional,
        hidden: false,
        cancelled: state.corpStatusFilter.cancelled,
    };
};

export const corpStatusFilterActions = {
    toggleConfirmed: "corp/statusFilterConfirmedToggle",
    toggleProvisional: "corp/statusFilterProvisionalToggle",
    toggleCancelled: "corp/statusFilterCancelledToggle",
};

const corpStatusFilterReducer = (
    state = {
        confirmed: true,
        provisional: true,
        hidden: false,
        cancelled: false,
    },
    action
) => {
    switch (action.type) {
        case corpStatusFilterActions.toggleConfirmed: {
            return {...state, confirmed: !state.confirmed};
        }
        case corpStatusFilterActions.toggleProvisional: {
            return {...state, provisional: !state.provisional};
        }
        case corpStatusFilterActions.toggleCancelled: {
            return {...state, cancelled: !state.cancelled};
        }
        default:
            return state;
    }
};

export default corpStatusFilterReducer;
