/** @format */

import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {useQuery} from "@apollo/react-hooks";
import {makeStyles} from "@material-ui/core/styles";
import gql from "graphql-tag";

const GET_MESSAGE = gql`
    query bannerMessage($app: String!) {
        bannerMessage(app: $app)
    }
`;

const useStyles = makeStyles((theme) => ({
    snackbar: ({colour}) => ({
        backgroundColor: colour || theme.gsc.teal,
    }),
}));

const GlobalAlert = ({colour}) => {
    const {loading, error, data} = useQuery(GET_MESSAGE, {
        variables: {app: "gscToday"},
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "cache-and-network",
    });

    const classes = useStyles({colour});
    const [open, setOpen] = React.useState(true);

    if (loading || error) return null;
    if (!data.bannerMessage) return null;

    const handleClose = (event, reason) => {
        if (reason === "clickaway") return;
        setOpen(false);
    };

    return (
        <Snackbar
            anchorOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            open={open}
            onClose={handleClose}
        >
            <SnackbarContent
                className={classes.snackbar}
                aria-describedby="client-snackbar"
                message={data.bannerMessage}
                action={[
                    <IconButton
                        key="close"
                        aria-label="close"
                        color="inherit"
                        onClick={handleClose}
                    >
                        <CloseIcon className={classes.icon} />
                    </IconButton>,
                ]}
            />
        </Snackbar>
    );
};

export default GlobalAlert;
