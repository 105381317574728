/** @format */

import React from "react";
import {
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {formatTimetableNote} from "../../utils/corpEventFilters";

const useStyles = makeStyles((theme) => ({
    tableWrapper: {
        width: "100%",
        overflowX: "auto",
        marginBottom: theme.spacing(3),
    },
    table: {
        minWidth: 600,
    },
    headerRow: {
        backgroundColor: "transparent",
    },
    headerText: {
        fontWeight: "bold",
    },
    fallback: {
        whiteSpace: "pre-wrap",
        fontFamily: "monospace",
    },
}));

const TimetableTable = ({title = null, tableData} = {}) => {
    const classes = useStyles();
    const [headCells, ...rowData] = tableData;
    return (
        <>
            {title && <Typography variant="h5">{title}</Typography>}
            <div className={classes.tableWrapper}>
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size="medium"
                    aria-label="table"
                >
                    <TableHead>
                        <TableRow className={classes.headerRow}>
                            {headCells.map((headCell, idx) => (
                                <TableCell
                                    key={idx}
                                    align="left"
                                    className={classes.headerText}
                                >
                                    {headCell}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rowData.map((row, idx) => {
                            return (
                                <TableRow hover tabIndex={-1} key={idx}>
                                    {row.map((cell, idx) => (
                                        <TableCell key={idx}>{cell}</TableCell>
                                    ))}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </div>
        </>
    );
};

const TimetableNote = ({note}) => {
    const classes = useStyles();
    const {fields, tables} = formatTimetableNote(note);

    if (!tables) return <TimetableTable tableData={fields} />;

    return tables.map((table, idx) => {
        const [[title], ...tableData] = table;
        const tableChecker = Array.from(
            new Set(tableData.map((row) => row.length))
        );
        if (tableChecker.length !== 1)
            return (
                <Typography
                    key={idx}
                    variant="body1"
                    className={classes.fallback}
                >
                    {note}
                </Typography>
            );
        return <TimetableTable key={idx} title={title} tableData={tableData} />;
    });
};

export default TimetableNote;
