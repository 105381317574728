/** @format */

import React from "react";
import {Grid, Typography} from "@material-ui/core";
import GSCDateHeader from "./DateHeader";
import GSCLoading from "./Loading";

const GSCWrappedLoading = (props) => (
    <Grid container>
        {props.heading && (
            <GSCDateHeader date={props.date}>{props.children}</GSCDateHeader>
        )}
        <Grid
            item
            xs={12}
            container
            direction="row"
            justify="center"
            alignItems="center"
        >
            <GSCLoading />
        </Grid>
        {props.mightBeLong && (
            <Grid
                item
                xs={12}
                container
                direction="row"
                justify="center"
                alignItems="center"
            >
                <Typography variant="body1">
                    This one might take a minute. Hang tight...
                </Typography>
            </Grid>
        )}
    </Grid>
);

export default GSCWrappedLoading;
