/** @format */

import React from "react";
import {Grid, Typography, Button} from "@material-ui/core";
import {useDispatch} from "react-redux";
import {push} from "connected-react-router";
import GSCDateHeader from "./DateHeader";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    actionButton: {
        margin: theme.spacing(1),
    },
    bodyCopy: {
        margin: theme.spacing(1, 0),
        textAlign: "center",
    },
}));

const GammaQLDataFetchError = ({theThing, refetch, showHeader}) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    return (
        <Grid container>
            {showHeader && (
                <GSCDateHeader>
                    <Typography variant="h4" component="h4">
                        {theThing}
                    </Typography>
                </GSCDateHeader>
            )}
            <Grid
                item
                xs={12}
                container
                direction="column"
                justify="center"
                alignItems="center"
            >
                <Typography variant="body1" className={classes.bodyCopy}>
                    {theThing} could not be retrieved for this date. Are you
                    offline, or outwith GSC?
                </Typography>
                <Typography variant="body1" className={classes.bodyCopy}>
                    If you're sure you're connected, you can try and fetch data
                    again. If you're outwith GSC, you'll need to log in before
                    you can access this data.
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                container
                justify="center"
                alignItems="center"
                className={classes.actionButton}
            >
                <Button
                    variant="text"
                    color="secondary"
                    onClick={() => refetch()}
                >
                    Try Again
                </Button>
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => dispatch(push("/auth/auto"))}
                    className={classes.actionButton}
                >
                    Log In
                </Button>
            </Grid>
        </Grid>
    );
};

export default GammaQLDataFetchError;
