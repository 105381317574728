/** @format */

import React from "react";
import {StyleSheet, Text} from "@react-pdf/renderer";

const styles = StyleSheet.create({
    bottomBorder: {
        borderTopColor: "#2e2d44",
        borderTopWidth: 2,
        borderTopStyle: "solid",
        width: "100%",
        marginTop: 6,
        fontSize: 6,
    },
});

const HorizontalRule = () => <Text style={styles.bottomBorder}>&nbsp;</Text>;

export default HorizontalRule;
