/** @format */

import React from "react";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemLink from "./ListItemLink";
import AddToHomeScreen from "./AddToHomeScreen";
import UserPic from "../auth/UserPic";
import {GSCAuthConsumer, isLoggedIn} from "../auth/";
import HomeIcon from "@material-ui/icons/Home";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import EventIcon from "@material-ui/icons/Event";
import ListIcon from "@material-ui/icons/ListAlt";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {appVersionString} from "../../config";

const useStyles = makeStyles((theme) => ({
    drawer: ({drawerWidth, open}) => ({
        width: open ? drawerWidth : 0,
        flexShrink: 0,
    }),
    drawerPaper: ({drawerWidth}) => ({
        width: drawerWidth,
    }),
    toolbar: theme.mixins.toolbar,
}));

const navItems = [
    {
        primary: "Dashboard",
        secondary: "Browse stats by date",
        icon: <HomeIcon />,
        to: "/",
    },
    {
        primary: "Numbers",
        secondary: "Compare visitor numbers",
        icon: <EqualizerIcon />,
        to: "/numbers",
    },
    {
        primary: "Events",
        secondary: "Details and sales history",
        icon: <EventIcon />,
        to: "/events",
    },
    {
        primary: "Corporate Events",
        secondary: "View function sheets",
        icon: <ListIcon />,
        to: "/corporate",
    },
];

const NavigationDrawer = ({drawerWidth, open, toggle, loggedIn, userName}) => {
    const classes = useStyles({drawerWidth, open});
    const theme = useTheme();
    const isMinSmScreen = useMediaQuery(theme.breakpoints.up("sm"));

    return (
        <nav
            className={isMinSmScreen ? classes.drawer : ""}
            aria-label="navigation menu"
        >
            <Drawer
                variant="persistent"
                anchor={theme.direction === "rtl" ? "right" : "left"}
                open={open}
                onClose={toggle}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.toolbar} />
                <List>
                    {navItems.map((item) => (
                        <ListItemLink
                            {...item}
                            key={item.primary}
                            toggler={toggle}
                        />
                    ))}
                    <Divider />
                    <ListItemLink
                        primary="Account"
                        secondary={
                            loggedIn ? userName : "Not currently logged in"
                        }
                        icon={<UserPic size={48} displaySize={32} />}
                        to="/auth"
                        toggler={toggle}
                    />
                    <Divider />
                    <AddToHomeScreen />
                    <ListItem>
                        <ListItemIcon>
                            <InfoIcon />
                        </ListItemIcon>
                        <ListItemText primary="" secondary={appVersionString} />
                    </ListItem>
                </List>
            </Drawer>
        </nav>
    );
};

const WrappedNavigationDrawer = ({drawerWidth, open, toggle}) => (
    <GSCAuthConsumer>
        {({auth}) => (
            <NavigationDrawer
                drawerWidth={drawerWidth}
                open={open}
                toggle={toggle}
                loggedIn={isLoggedIn() && auth.idToken}
                userName={auth.userName}
            />
        )}
    </GSCAuthConsumer>
);

export default WrappedNavigationDrawer;
