/** @format */

import React from "react";
import {Grid, Card, Collapse} from "@material-ui/core";
import {EventsCardHeader, EventsCardBody} from "./CardComponents";
import {makeStyles} from "@material-ui/core/styles";
import {parseISO, isToday, isPast, isSameDay, format} from "date-fns";
import {autoExpandBreakpoint} from "../../config";

const useStyles = makeStyles((theme) => ({
    eventsCardContainer: {
        padding: theme.spacing(1),
    },
    eventsCard: ({colour}) => ({
        border: `1px solid ${theme.gsc[colour]}`,
    }),
}));

const isMultiDayEvent = (eventStart, eventEnd) =>
    !isSameDay(eventStart, eventEnd);

const formatStartAndEnd = (start, end, multi = false) => {
    const formatString = multi ? "EEE do, HH:mm" : "HH:mm";
    return `${format(start, formatString)} – ${format(end, formatString)}`;
};

const GSCSingleEventCard = ({event, category, colour}) => {
    const [cardIsExpanded, setCardIsExpanded] = React.useState(
        window.innerWidth > autoExpandBreakpoint
    );
    const toggleCard = () => setCardIsExpanded(!cardIsExpanded);

    const eventStartsAt = event.DateTimeStart
        ? parseISO(event.DateTimeStart)
        : event.ResDate && event.StartTime
        ? parseISO(`${event.ResDate}T${event.StartTime}`)
        : event.eventStart
        ? new Date(Number(event.eventStart))
        : null;
    const eventEndsAt = event.DateTimeEnd
        ? parseISO(event.DateTimeEnd)
        : event.ResDate && event.EndTime
        ? parseISO(`${event.ResDate}T${event.EndTime}`)
        : event.eventEnd
        ? new Date(Number(event.eventEnd))
        : null;
    const cardIsDimmed = isToday(eventEndsAt) && isPast(eventEndsAt);
    const isMultiDay = isMultiDayEvent(eventStartsAt, eventEndsAt);

    const classes = useStyles({colour, cardIsExpanded, cardIsDimmed});

    const primaryHeader = event.ResName || event.eventName;
    const secondaryHeader = Boolean(eventStartsAt)
        ? event.Category === "Corporate"
            ? formatStartAndEnd(eventStartsAt, eventEndsAt, isMultiDay)
            : format(eventStartsAt, "HH:mm")
        : "";

    return (
        <Grid
            item
            xs={12}
            md={6}
            lg={4}
            xl={3}
            className={classes.eventsCardContainer}
        >
            <Card variant="outlined" className={classes.eventsCard}>
                <EventsCardHeader
                    onClick={toggleCard}
                    primaryHeader={primaryHeader}
                    secondaryHeader={secondaryHeader}
                    colour={colour}
                    cardIsExpanded={cardIsExpanded}
                    cardIsDimmed={cardIsDimmed}
                />
                <Collapse in={cardIsExpanded}>
                    <EventsCardBody
                        event={event}
                        colour={colour}
                        cardIsDimmed={cardIsDimmed}
                        eventStartsAt={eventStartsAt}
                        eventEndsAt={eventEndsAt}
                    />
                </Collapse>
            </Card>
        </Grid>
    );
};

export default GSCSingleEventCard;
