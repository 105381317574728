/** @format */

import React from "react";
import {
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
} from "@material-ui/core";
import StatusAvatar from "./StatusAvatar";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    avatar: {
        color: theme.gsc.purple,
        backgroundColor: theme.palette.background.paper,
    },
    reverseText: {
        display: "flex",
        flexDirection: "column-reverse",
    },
}));

const EventListItem = ({
    primary,
    secondary,
    icon = null,
    status = null,
} = {}) => {
    const classes = useStyles();
    return (
        <ListItem>
            {(icon || status) && (
                <ListItemAvatar>
                    {status ? (
                        <StatusAvatar status={status} />
                    ) : (
                        <Avatar className={classes.avatar}>{icon}</Avatar>
                    )}
                </ListItemAvatar>
            )}
            <ListItemText
                inset={!icon && !status}
                classes={{root: classes.reverseText}}
                primary={primary || "—"}
                secondary={secondary}
            />
        </ListItem>
    );
};

export default EventListItem;
