/** @format */

import React from "react";
import {Grid, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import ProgressBar from "../general/ProgressBar";
import {
    generateLinkForDateStyle,
    formatDate,
    totaliser,
    applySeparator,
} from "./helpers";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(1, 0),
    },
    dateTitle: {
        textDecoration: "none",
        color: theme.gsc.navy,
    },
    progressContainer: {
        width: "100%",
    },
    footer: {
        backgroundColor: theme.gsc.offwhite,
        padding: theme.spacing(2, 3),
    },
    comparatorText: ({isNegative}) => ({
        color: isNegative ? theme.gsc.red : theme.gsc.green,
    }),
}));

const Comparator = ({prevValue, currentValue}) => {
    const percentageChange =
        (Number(currentValue) - Number(prevValue)) * (100 / Number(prevValue));
    const isNegative = percentageChange < 0;
    const classes = useStyles({isNegative});
    if (!currentValue || !prevValue) return null;
    return (
        <Typography variant="body2" className={classes.comparatorText}>
            &nbsp;{`(${isNegative ? "" : "+"}${percentageChange.toFixed(1)}%)`}
        </Typography>
    );
};

export const FooterTotal = ({numbers, showComparison, comparisonData}) => {
    const classes = useStyles();
    const {total, totalDisplay} = totaliser(numbers);
    const comparisonTotal =
        showComparison && Array.isArray(comparisonData)
            ? comparisonData.reduce(
                  (accumulator, value) => accumulator + Number(value),
                  0
              )
            : 0;
    return (
        <Grid
            item
            xs={12}
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            className={classes.footer}
        >
            <Grid
                item
                xs={9}
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
            >
                <Typography variant="h6">Total</Typography>
            </Grid>
            <Grid
                item
                xs={3}
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
            >
                <Typography variant="h6">{totalDisplay}</Typography>
                {showComparison && (
                    <Comparator
                        prevValue={comparisonTotal}
                        currentValue={total}
                        margin={false}
                    />
                )}
            </Grid>
        </Grid>
    );
};

export const SingleDayBar = ({
    date,
    dateStyle,
    total,
    showComparison,
    comparisonTotal,
    max,
    deepLinks,
}) => {
    const classes = useStyles();
    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.root}
        >
            <Grid
                item
                xs={12}
                container
                direction="row"
                justify="space-between"
                alignItems="center"
            >
                <Grid
                    item
                    xs={9}
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                >
                    {deepLinks ? (
                        <Link
                            to={generateLinkForDateStyle(date, dateStyle)}
                            className={classes.dateTitle}
                        >
                            <Typography variant="h6">
                                {formatDate(date, dateStyle)}
                            </Typography>
                        </Link>
                    ) : (
                        <Typography variant="h6" className={classes.dateTitle}>
                            {formatDate(date, dateStyle)}
                        </Typography>
                    )}
                </Grid>
                <Grid
                    item
                    xs={3}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                >
                    <Typography variant="body1">
                        {total ? applySeparator(total) : 0}
                    </Typography>
                    {showComparison && (
                        <Comparator
                            prevValue={comparisonTotal}
                            currentValue={total}
                        />
                    )}
                </Grid>
            </Grid>
            <Grid item xs={12} className={classes.progressContainer}>
                <ProgressBar total={total} max={max} />
            </Grid>
        </Grid>
    );
};
