/** @format */

import {toDate, parseISO} from "date-fns";

const dateify = (dateArg) => {
    if (dateArg instanceof Date || typeof dateArg === "number") {
        const dateAttempt = toDate(dateArg);
        if (!isNaN(dateAttempt)) return dateAttempt;
    }

    if (typeof dateArg === "string") {
        // Parses complete ISO 8601 format strings as well as partial implementations
        const stringAttempt = parseISO(dateArg);
        if (!isNaN(stringAttempt)) return stringAttempt;

        const stringToNumAttempt = toDate(Number(dateArg));
        if (!isNaN(stringToNumAttempt)) return stringToNumAttempt;
    }

    return undefined;
};

export default dateify;
