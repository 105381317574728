/** @format */

import React, {useState, useEffect} from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AddToHomeScreenIcon from "@material-ui/icons/AddToHomeScreen";

const listenForPromptEvent = (updateDeferredPrompt) => {
    window.addEventListener("beforeinstallprompt", (e) => {
        e.preventDefault();
        // Stash the event so it can be triggered later.
        updateDeferredPrompt(e);
    });
};

const AddToHomeScreen = () => {
    const [deferredPrompt, updateDeferredPrompt] = useState(null);
    useEffect(() => listenForPromptEvent(updateDeferredPrompt), []);

    const install = () => {
        deferredPrompt.prompt();
        updateDeferredPrompt(null);
    };
    const isRunningStandalone =
        window.matchMedia("(display-mode: standalone)").matches ||
        window.navigator.standalone === true;
    return (
        !isRunningStandalone &&
        deferredPrompt && (
            <ListItem button onClick={install}>
                <ListItemIcon>
                    <AddToHomeScreenIcon />
                </ListItemIcon>
                <ListItemText
                    primary="Install as an app"
                    secondary="Add GSC Today to homescreen or desktop"
                />
            </ListItem>
        )
    );
};

export default AddToHomeScreen;
