/** @format */

import React from "react";
import {
    Grid,
    Typography,
    CardContent,
    Collapse,
    Button,
    Tooltip,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from "@material-ui/core";
import ProgressBar from "../general/ProgressBar";
import TimerIcon from "@material-ui/icons/Timer";
import LocationIcon from "@material-ui/icons/Room";
import UserIcon from "@material-ui/icons/Person";
import TicketIcon from "@material-ui/icons/ConfirmationNumber";
import PeopleIcon from "@material-ui/icons/People";
import CategoryIcon from "@material-ui/icons/Category";
import CollapseIcon from "@material-ui/icons/ExpandLess";
import ExpandIcon from "@material-ui/icons/ExpandMore";
import StatusAvatar from "../corporateEvent/StatusAvatar";
import {useDispatch} from "react-redux";
import {push} from "connected-react-router";
import {getCorpEventType, getEventSpaces} from "../../utils/corpEventFilters";
import {websiteNodeRoot, bbfcIconUrl, bbfcIconFiletype} from "../../config";
import {formatDistanceStrict, format} from "date-fns";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    eventsCardHeader: ({colour, cardIsExpanded, cardIsDimmed}) => ({
        borderBottom: cardIsExpanded
            ? `1px solid ${theme.gsc[colour]}`
            : "none",
        padding: theme.spacing(1, 2),
        cursor: "pointer",
        backgroundColor: cardIsDimmed ? theme.gsc.offwhite : theme.gsc.white,
    }),
    eventsCardBody: ({cardIsDimmed}) => ({
        backgroundColor: cardIsDimmed ? theme.gsc.offwhite : theme.gsc.white,
        padding: theme.spacing(1, 1, 0, 1),
    }),
    progressContainer: {
        width: "100%",
        marginTop: theme.spacing(-1),
        padding: theme.spacing(0, 2, 2, 2),
    },
    detailsButtonContainer: {
        marginTop: theme.spacing(2),
    },
    descriptionContainer: {
        padding: theme.spacing(0, 2),
    },
    iconColour: ({colour}) => ({
        color: theme.gsc[colour],
    }),
}));

export const EventsCardHeader = ({
    onClick,
    primaryHeader,
    secondaryHeader,
    colour,
    cardIsExpanded,
    cardIsDimmed,
}) => {
    const classes = useStyles({colour, cardIsExpanded, cardIsDimmed});
    return (
        <CardContent onClick={onClick} className={classes.eventsCardHeader}>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="flex-start"
            >
                <Typography variant="h6" component="h5">
                    {secondaryHeader}
                </Typography>
                <Typography variant="h5" component="h4">
                    {primaryHeader}
                </Typography>
            </Grid>
        </CardContent>
    );
};

const EventData = ({
    icon,
    tooltip,
    value,
    colour,
    specialTypography,
    children,
}) => (
    <ListItem>
        <ListItemIcon className={colour}>{icon}</ListItemIcon>
        <ListItemText>
            {tooltip ? (
                <Tooltip
                    arrow
                    placement="right"
                    title={<Typography variant="body2">{tooltip}</Typography>}
                >
                    {children || (
                        <Typography variant={specialTypography || "body1"}>
                            {value}
                        </Typography>
                    )}
                </Tooltip>
            ) : (
                children || (
                    <Typography variant={specialTypography || "body1"}>
                        {value}
                    </Typography>
                )
            )}
        </ListItemText>
    </ListItem>
);

const EventsDataList = ({event, colour, eventStartsAt, eventEndsAt}) => {
    const classes = useStyles({colour});
    const eventDuration =
        eventStartsAt && eventEndsAt
            ? formatDistanceStrict(eventEndsAt, eventStartsAt, {
                  unit: "minute",
                  roundingMethod: "round",
              })
            : null;
    const eventLocation = event.Area || event.Location || getEventSpaces(event);
    const totalToDisplay = event.TotalBooked ? event.TotalBooked : 0;
    const corpEventType = getCorpEventType(event);

    return (
        <>
            <List>
                {eventDuration && event.Category !== "Corporate" && (
                    <EventData
                        name="Duration"
                        icon={<TimerIcon />}
                        value={eventDuration}
                        colour={classes.iconColour}
                        tooltip={`Ends at ${format(eventEndsAt, "HH:mm")}`}
                    />
                )}
                {corpEventType && (
                    <EventData
                        name="EventType"
                        icon={<CategoryIcon />}
                        value={corpEventType}
                        colour={classes.iconColour}
                    />
                )}
                {event.bookingStatus && (
                    <EventData
                        name="BookingStatus"
                        icon={
                            <StatusAvatar
                                justify="flex-start"
                                status={event.bookingStatus}
                            />
                        }
                        value={event.bookingStatus}
                    />
                )}
                {eventLocation && (
                    <EventData
                        name="Location"
                        icon={<LocationIcon />}
                        value={eventLocation}
                        colour={classes.iconColour}
                    />
                )}
                {event.Age && !event.FilmCertification && (
                    <EventData
                        name="Age"
                        icon={<UserIcon />}
                        value={event.Age}
                        colour={classes.iconColour}
                    />
                )}
                {event.FilmCertification && (
                    <EventData
                        name="FilmCertification"
                        icon={<UserIcon />}
                        value={event.Age}
                        colour={classes.iconColour}
                        tooltip={event.FilmCertification.toUpperCase()}
                    >
                        <img
                            src={`${bbfcIconUrl}${event.FilmCertification.toUpperCase()}${bbfcIconFiletype}`}
                            alt={event.FilmCertification.toUpperCase()}
                            height={32}
                        />
                    </EventData>
                )}
                {(event.Capacity || event.eventAttendees) && (
                    <EventData
                        name="Tickets"
                        icon={event.Capacity ? <TicketIcon /> : <PeopleIcon />}
                        value={
                            event.Capacity
                                ? `${totalToDisplay} / ${event.Capacity}`
                                : event.eventAttendees
                        }
                        colour={classes.iconColour}
                        specialTypography={event.Capacity ? "h6" : undefined}
                    />
                )}
            </List>
            {event.Capacity && (
                <Grid item xs={12} className={classes.progressContainer}>
                    <ProgressBar total={totalToDisplay} max={event.Capacity} />
                </Grid>
            )}
        </>
    );
};

const EventsDetailsCollapse = ({event}) => {
    const classes = useStyles();
    const [descriptionIsExpanded, setDescriptionIsExpanded] =
        React.useState(false);
    const toggleDesc = () => setDescriptionIsExpanded(!descriptionIsExpanded);
    return (
        <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="center"
            className={classes.descriptionContainer}
        >
            <Collapse in={descriptionIsExpanded}>
                {event.Description && (
                    <Grid item xs={12}>
                        <Typography variant="h6" component="h6">
                            Description
                        </Typography>
                        {event.Description.split("\n").map((line, index) => (
                            <Typography key={index} variant="body1">
                                {line.replace(/\s/g, " ")}
                            </Typography>
                        ))}
                    </Grid>
                )}
                {event.InformationPage && (
                    <Grid
                        item
                        xs={12}
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                    >
                        <Button
                            variant="text"
                            color="secondary"
                            href={`${websiteNodeRoot}${event.InformationPage}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            View page on GSC's website
                        </Button>
                    </Grid>
                )}
            </Collapse>
            <Grid item xs={12} className={classes.detailsButtonContainer}>
                {event.InfoURL ? (
                    <Button
                        variant="text"
                        size="small"
                        color="secondary"
                        href={event.InfoURL}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        View event on{" "}
                        {event.InfoURL.includes("ticketmaster")
                            ? "Ticketmaster"
                            : event.InfoURL.includes("list.co.uk")
                            ? "The List"
                            : "external site"}
                    </Button>
                ) : (
                    <Button
                        variant="text"
                        size="small"
                        color="secondary"
                        endIcon={
                            descriptionIsExpanded ? (
                                <CollapseIcon />
                            ) : (
                                <ExpandIcon />
                            )
                        }
                        onClick={toggleDesc}
                    >
                        {descriptionIsExpanded ? "Hide" : "More"} Details
                    </Button>
                )}
            </Grid>
        </Grid>
    );
};

const CorporateEventLink = ({event}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.descriptionContainer}
        >
            <Grid item xs={12} className={classes.detailsButtonContainer}>
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() =>
                        dispatch(push(`/corporate/event/${event.masterBookId}`))
                    }
                >
                    Full Details
                </Button>
            </Grid>
        </Grid>
    );
};

export const EventsCardBody = ({
    event,
    colour,
    cardIsDimmed,
    eventStartsAt,
    eventEndsAt,
}) => {
    const classes = useStyles({colour, cardIsDimmed});
    return (
        <CardContent className={classes.eventsCardBody}>
            <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
            >
                <EventsDataList
                    event={event}
                    colour={colour}
                    eventStartsAt={eventStartsAt}
                    eventEndsAt={eventEndsAt}
                />
                {(event.Description ||
                    event.InformationPage ||
                    event.InfoURL) && <EventsDetailsCollapse event={event} />}
                {event.Category === "Corporate" && (
                    <CorporateEventLink event={event} />
                )}
            </Grid>
        </CardContent>
    );
};
