/** @format */

import React, {useState} from "react";
import {
    Grid,
    Card,
    CardContent,
    CardActions,
    Typography,
    Button,
    Collapse,
} from "@material-ui/core";
import {GSCAuthConsumer, isLoggedIn} from ".";
import UserPic from "./UserPic";
import LastUpdatedTooltip from "../general/LastUpdatedTooltip";
import CollapseIcon from "@material-ui/icons/ExpandLess";
import ExpandIcon from "@material-ui/icons/ExpandMore";
import {useDispatch} from "react-redux";
import {push} from "connected-react-router";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        margin: theme.spacing(3),
    },
    card: {
        border: `1px solid ${theme.gsc.navy}`,
        padding: theme.spacing(1),
        margin: theme.spacing(3, 0),
    },
    cardActions: {
        display: "flex",
        justifyContent: "flex-end",
    },
}));

const getGroups = async (token) => {
    const requestUrl = `https://graph.microsoft.com/v1.0/me/memberOf`;
    const response = await fetch(requestUrl, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    const json = await response.json();
    return json;
};

const LinkButton = ({variant, to, text}) => {
    const dispatch = useDispatch();
    return (
        <Button
            variant={variant}
            color="secondary"
            onClick={() => dispatch(push(to))}
        >
            {text}
        </Button>
    );
};

const UserCard = ({loggedIn, auth}) => {
    const classes = useStyles();
    return (
        <Grid container>
            <Grid item xs={12} md={6}>
                <Card className={classes.card}>
                    <CardContent>
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                        >
                            {auth.pics && auth.pics[120] && (
                                <Grid
                                    item
                                    xs={3}
                                    container
                                    justify="center"
                                    alignItems="flex-start"
                                >
                                    <UserPic
                                        size={loggedIn ? 120 : null}
                                        displaySize={loggedIn ? 60 : null}
                                    />
                                </Grid>
                            )}
                            <Grid
                                item
                                xs={auth.pics && auth.pics[120] ? 9 : 12}
                                container
                                direction="column"
                                justify="center"
                                alignItems="flex-start"
                            >
                                {loggedIn ? (
                                    <>
                                        <Typography variant="h6" component="h4">
                                            Logged in as
                                        </Typography>
                                        <Typography variant="h5" component="h3">
                                            {auth.userName}
                                        </Typography>
                                        <LastUpdatedTooltip
                                            introText="Last authenticated"
                                            lastSync={
                                                (auth.idToken.iat + 300) *
                                                1000 /* MS JWT iat has an extra 5 minutes to allow for clock skew*/
                                            }
                                            placement="right"
                                        />
                                    </>
                                ) : (
                                    <Typography variant="h5" component="h3">
                                        Not logged in
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions classes={{root: classes.cardActions}}>
                        {loggedIn ? (
                            <>
                                <LinkButton
                                    variant="text"
                                    to="/auth/logout"
                                    text="Log Out"
                                />
                                <LinkButton
                                    variant="outlined"
                                    to="/"
                                    text="Return to Dashboard"
                                />
                            </>
                        ) : (
                            <>
                                <LinkButton
                                    variant="text"
                                    to="/"
                                    text="Return to Dashboard"
                                />
                                <LinkButton
                                    variant="outlined"
                                    to="/auth/login"
                                    text="Log In"
                                />
                            </>
                        )}
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    );
};

const GSCAuthDetails = ({loggedIn, auth}) => {
    const classes = useStyles();
    const [idExpanded, setIdExpanded] = useState(false);
    const [groupsVisible, setGroupsVisible] = useState(true);
    const [groups, setGroups] = useState(null);

    return (
        <Grid container className={classes.gridContainer}>
            <Grid
                item
                xs={12}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
            >
                <Typography variant="h4" component="h2">
                    Account Details
                </Typography>
                <UserCard loggedIn={loggedIn} auth={auth} />
            </Grid>
            {loggedIn && (
                <Grid
                    item
                    xs={12}
                    md={6}
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                >
                    <Button
                        variant="text"
                        color="secondary"
                        size="small"
                        endIcon={idExpanded ? <CollapseIcon /> : <ExpandIcon />}
                        onClick={() => setIdExpanded(!idExpanded)}
                    >
                        {idExpanded ? "Hide" : "Show"} ID Details
                    </Button>
                    <Button
                        variant="text"
                        color="secondary"
                        size="small"
                        endIcon={
                            groups ? (
                                groupsVisible ? (
                                    <CollapseIcon />
                                ) : (
                                    <ExpandIcon />
                                )
                            ) : undefined
                        }
                        onClick={async () =>
                            groups
                                ? setGroupsVisible(!groupsVisible)
                                : setGroups(await getGroups(auth.accessToken))
                        }
                    >
                        {groups ? (groupsVisible ? "Hide" : "Show") : "Get"}{" "}
                        Groups
                    </Button>
                </Grid>
            )}
            <Grid item xs={12}>
                <Collapse in={idExpanded}>
                    <pre>{JSON.stringify(auth.idToken, null, 2)}</pre>
                </Collapse>
            </Grid>
            <Grid item xs={12}>
                <Collapse in={groupsVisible}>
                    {groups && <pre>{JSON.stringify(groups, null, 2)}</pre>}
                </Collapse>
            </Grid>
        </Grid>
    );
};

const WrappedAuthDetails = () => (
    <GSCAuthConsumer>
        {({auth, setAuth}) => (
            <GSCAuthDetails
                loggedIn={isLoggedIn() && auth.idToken}
                auth={auth}
                setAuth={setAuth}
            />
        )}
    </GSCAuthConsumer>
);

export default WrappedAuthDetails;
