/** @format */

import React from "react";
import {Text, View, StyleSheet} from "@react-pdf/renderer";
import {formatTimetableNote} from "../../../utils/corpEventFilters";

const styles = StyleSheet.create({
    section: {
        margin: "2 6",
        padding: 5,
    },
    rowContainer: {
        margin: 5,
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    columnContainer: {
        margin: 5,
        flexDirection: "column",
        justifyContent: "space-between",
    },
    heading: {
        fontFamily: "ProximaNova",
        color: "#2e2d44",
        margin: "3 0",
    },
    h1size: {
        fontSize: "24pt",
    },
    h2size: {
        fontSize: "20pt",
    },
    h3size: {
        fontSize: "16pt",
    },
    bodyText: {
        margin: 1,
        fontFamily: "Arial",
        fontSize: "10pt",
    },
    fallback: {
        margin: 1,
        fontFamily: "Arial",
        fontSize: "10pt",
    },
    bold: {
        fontWeight: "bold",
    },
    tableRowContainer: {
        margin: "1 3",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    tableCell: {
        margin: 1,
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "15%",
    },
    tableCellNarrow: {
        margin: 1,
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "11%",
    },
    tableCellWide: {
        margin: 1,
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "35%",
    },
});

const TableHeaderText = ({children, narrow, wide}) => (
    <View
        style={
            narrow
                ? styles.tableCellNarrow
                : wide
                ? styles.tableCellWide
                : styles.tableCell
        }
    >
        <Text style={[styles.bodyText, styles.bold]}>{children}</Text>
    </View>
);
const TableBodyText = ({children, narrow, wide}) => (
    <View
        style={
            narrow
                ? styles.tableCellNarrow
                : wide
                ? styles.tableCellWide
                : styles.tableCell
        }
    >
        <Text style={styles.bodyText}>{children}</Text>
    </View>
);

const TableRow = ({row, headCells}) => (
    <View style={styles.tableRowContainer}>
        {row.map((cell, idx) => {
            const correspondingHeader = headCells[idx]?.trim() || null;
            return (
                <TableBodyText
                    key={idx}
                    narrow={
                        correspondingHeader === "Time" ||
                        correspondingHeader === "Times"
                    }
                    wide={
                        correspondingHeader === "Notes" ||
                        correspondingHeader === "Note"
                    }
                >
                    {cell}
                </TableBodyText>
            );
        })}
    </View>
);

const InnerTable = ({title = null, tableData} = {}) => {
    const [headCells, ...rowData] = tableData;
    return (
        <View style={styles.columnContainer}>
            {title && (
                <Text
                    style={[styles.heading, styles.h3size]}
                    minPresenceAhead={30}
                >
                    {title}
                </Text>
            )}
            <View style={styles.tableRowContainer}>
                {headCells.map((headCell, idx) => {
                    const header = headCell?.trim() || null;
                    return (
                        <TableHeaderText
                            key={idx}
                            narrow={header === "Time" || header === "Times"}
                            wide={header === "Notes" || header === "Note"}
                        >
                            {header}
                        </TableHeaderText>
                    );
                })}
            </View>
            {rowData.map((row, idx) => (
                <TableRow key={idx} row={row} headCells={headCells} />
            ))}
        </View>
    );
};

const TimetableTable = ({note}) => {
    if (!note) return null;
    const {fields, tables} = formatTimetableNote(note);

    return (
        <View style={styles.section}>
            <Text style={[styles.heading, styles.h2size]} minPresenceAhead={50}>
                Timetable
            </Text>
            {tables ? (
                tables.map((table, idx) => {
                    const [[title], ...tableData] = table;
                    const tableChecker = Array.from(
                        new Set(tableData.map((row) => row.length))
                    );
                    if (tableChecker.length !== 1)
                        /* Line breaks in the fallback need to be just \n and not \r\n, otherwise \r is rendered as a "missing character" square */
                        return (
                            <Text style={styles.fallback}>
                                {note.replace(/\r\n/gi, "\n")}
                            </Text>
                        );
                    return (
                        <InnerTable
                            key={idx}
                            title={title}
                            tableData={tableData}
                        />
                    );
                })
            ) : (
                <InnerTable tableData={fields} />
            )}
        </View>
    );
};

export default TimetableTable;
