/** @format */

import React from "react";
import {
    Grid,
    Typography,
    Button,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    useMediaQuery,
} from "@material-ui/core";
import {PDFViewer, BlobProvider} from "@react-pdf/renderer";
import EventsDocument from "./EventsDocument";
import GSCWrappedLoading from "../../general/WrappedLoading";
import NavIconButton from "../../general/NavIconButton";
import BackIcon from "@material-ui/icons/ArrowBack";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {
    getNonEmptyNotes,
    getAllSpaceNoteTypes,
    capitalFirst,
} from "../../../utils/corpEventFilters";

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        margin: theme.spacing(1, 0),
        padding: theme.spacing(1),
        [theme.breakpoints.up("md")]: {
            padding: theme.spacing(2, 4),
        },
    },
    sectionTitle: {
        padding: theme.spacing(0),
    },
    eventsContainer: {
        width: "100%",
    },
    controlContainer: {
        margin: theme.spacing(1, 0),
    },
}));

const NoteSelector = ({open, onClose, includedNoteTypes}) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const [value, setValue] = React.useState(includedNoteTypes);

    React.useEffect(() => {
        if (!open) {
            setValue(includedNoteTypes);
        }
    }, [includedNoteTypes, open]);

    const handleCancel = () => {
        onClose();
    };

    const handleApply = () => {
        onClose(value);
    };

    const handleChange = (noteType) =>
        setValue({...value, [noteType]: !value[noteType]});
    const allSelected =
        Object.keys(value).filter((noteType) => value[noteType]).length ===
        Object.keys(value).length;
    const selectAll = () =>
        setValue(
            Object.fromEntries(
                Object.entries(value).map(([key, value]) => [
                    key,
                    allSelected ? false : true,
                ])
            )
        );

    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            fullWidth
            maxWidth="xs"
            fullScreen={fullScreen}
            aria-labelledby="confirmation-dialog-title"
            open={open}
        >
            <DialogTitle id="confirmation-dialog-title">Note Types</DialogTitle>
            <DialogContent dividers>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={allSelected}
                                onChange={selectAll}
                                value="All"
                            />
                        }
                        label={<b>Select All</b>}
                    />
                </FormGroup>
                <FormGroup>
                    {Object.keys(value)
                        .sort()
                        .map(
                            (noteType) =>
                                noteType !== "timetable" && (
                                    <FormControlLabel
                                        key={noteType}
                                        control={
                                            <Checkbox
                                                checked={value[noteType]}
                                                onChange={() =>
                                                    handleChange(noteType)
                                                }
                                                value={noteType}
                                            />
                                        }
                                        label={capitalFirst(noteType)}
                                    />
                                )
                        )}
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleApply} color="primary">
                    Apply
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const PrintRenderer = ({eventsData}) => {
    console.log(
        "🚀 ~ file: PrintRenderer.js ~ line 143 ~ PrintRenderer ~ eventsData",
        eventsData
    );

    const classes = useStyles();

    const allSpaceNoteTypes = eventsData.reduce((accumulator, event) => {
        return [...accumulator, ...getAllSpaceNoteTypes(event.spaces)];
    }, []);
    console.log(
        "🚀 ~ file: PrintRenderer.js ~ line 150 ~ allSpaceNoteTypes ~ allSpaceNoteTypes",
        allSpaceNoteTypes
    );

    const allGeneralNoteTypes = eventsData.reduce((accumulator, event) => {
        const nonEmptyNotes = getNonEmptyNotes(event.eventNotes);
        const nonEmptyNoteTypes = nonEmptyNotes.map(([key, value]) => key);
        return [...accumulator, ...nonEmptyNoteTypes];
    }, []);
    console.log(
        "🚀 ~ file: PrintRenderer.js ~ line 157 ~ allGeneralNoteTypes ~ allGeneralNoteTypes",
        allGeneralNoteTypes
    );
    const allNoteTypes = Array.from(
        new Set([...allSpaceNoteTypes, ...allGeneralNoteTypes])
    );
    const initialNoteTypes = allNoteTypes.reduce(
        (accumulator, type) => ({...accumulator, [type]: true}),
        {}
    );

    const [includedNoteTypes, setIncludedNoteTypes] =
        React.useState(initialNoteTypes);

    const [noteSelectorOpen, setNoteSelectorOpen] = React.useState(false);
    const handleNoteSelectorClose = (newValue) => {
        setNoteSelectorOpen(false);
        if (newValue) {
            setIncludedNoteTypes(newValue);
        }
    };

    const EventsDoc = React.useMemo(
        () => (
            <EventsDocument
                events={eventsData}
                includedNoteTypes={includedNoteTypes}
            />
        ),
        [eventsData, includedNoteTypes]
    );

    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.gridContainer}
        >
            <Grid
                item
                xs={12}
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
            >
                <NavIconButton
                    icon={<BackIcon />}
                    to="/corporate"
                    label="navigate back"
                />
                <Typography
                    variant="h5"
                    component="h4"
                    className={classes.sectionTitle}
                >
                    Corporate Events
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                className={classes.controlContainer}
            >
                <Typography variant="h4" component="h2">
                    Print Events
                </Typography>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setNoteSelectorOpen(true)}
                >
                    Filter Note Types
                </Button>
                <NoteSelector
                    open={noteSelectorOpen}
                    onClose={handleNoteSelectorClose}
                    includedNoteTypes={includedNoteTypes}
                    setIncludedNoteTypes={setIncludedNoteTypes}
                />
            </Grid>
            <Grid item xs={12} className={classes.eventsContainer}>
                <BlobProvider document={EventsDoc}>
                    {({blob, url, loading, error}) =>
                        loading ? (
                            <GSCWrappedLoading heading={true}>
                                <Typography variant="h5" component="h4">
                                    Generating PDF...
                                </Typography>
                            </GSCWrappedLoading>
                        ) : (
                            <PDFViewer
                                width="100%"
                                height={0.75 * window.innerHeight}
                            >
                                {EventsDoc}
                            </PDFViewer>
                        )
                    }
                </BlobProvider>
            </Grid>
        </Grid>
    );
};

export default PrintRenderer;
