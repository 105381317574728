/** @format */

import React from "react";
import {Grid, Typography} from "@material-ui/core";
import GSCNumbersDetails from "./NumbersDetails";
import {isPast, isFuture, isToday} from "date-fns";

const getTimeTravelStatus = (date) => {
    if (isToday(date)) return "today";
    if (isFuture(date)) return "future";
    if (isPast(date)) return "past";
};

const getTimeTravelPhrase = (timetravel, sales, total) => {
    const isSingular = Math.abs(total) === 1;
    const admittedTo = total < 0 ? "refunded from" : "admitted to";
    const people = isSingular ? "person" : "people";
    const verbs = {
        have: isSingular ? "has" : "have",
        are: isSingular ? "is" : "are",
        were: isSingular ? "was" : "were",
    };
    switch (timetravel) {
        case "today":
            if (sales)
                return `${people} ${verbs.have} been ${admittedTo} the Science Mall today`;
            else
                return `${people} ${verbs.are} booked into the Science Mall today`;
        case "past":
            return `${people} ${verbs.were} ${admittedTo} the Science Mall`;
        case "future":
            return `${people} ${verbs.are} booked into the Science Mall on this date`;
        default:
            return false;
    }
};

const GSCNumberPhrase = ({number, phrase, width = 12} = {}) => (
    <Grid
        item
        xs={width}
        container
        direction="column"
        justify="center"
        alignItems="center"
    >
        <Typography variant="h4" align="center">
            {number}
        </Typography>
        <Typography variant="body1" align="center">
            {phrase}
        </Typography>
    </Grid>
);

export const GSCTabTotal = ({
    date,
    totalToDisplay,
    adults,
    children,
    sales,
    expanded,
}) => {
    const timetravel = getTimeTravelStatus(date);
    const timeTravelPhrase = getTimeTravelPhrase(
        timetravel,
        sales,
        totalToDisplay
    );
    return (
        <>
            <GSCNumberPhrase
                number={Math.abs(totalToDisplay)}
                phrase={timeTravelPhrase}
            />
            <GSCNumbersDetails
                timetravel={timetravel}
                stats={[
                    {label: "Adults", value: adults},
                    {label: "Children", value: children},
                    {label: "Sales", value: sales},
                ]}
                expanded={expanded}
            />
        </>
    );
};

export const GSCTabEducation = ({totalEducation}) => (
    <GSCNumberPhrase
        number={totalEducation}
        phrase="education visitors booked"
    />
);

export const GSCTabCommunity = ({date, totalCommunity, communityMembers}) => {
    const timetravel = getTimeTravelStatus(date);
    return timetravel === "future" ? (
        <GSCNumberPhrase
            number={totalCommunity}
            phrase="community visitors booked"
        />
    ) : (
        <Grid container direction="row" justify="center" alignItems="center">
            <GSCNumberPhrase
                number={totalCommunity}
                phrase="community visitors booked"
                width={6}
            />
            <GSCNumberPhrase
                number={communityMembers.toLocaleString()}
                phrase="groups with Community Membership"
                width={6}
            />
        </Grid>
    );
};

export const GSCTabPrebooked = ({totalPhone, totalOnline}) => (
    <Grid container direction="row" justify="center" alignItems="center">
        <GSCNumberPhrase
            number={totalPhone}
            phrase="prebooked over the phone"
            width={6}
        />
        <GSCNumberPhrase
            number={totalOnline}
            phrase="prebooked online"
            width={6}
        />
    </Grid>
);

export const GSCTabMembers = ({date, members, totalMembers, expanded}) => {
    const timetravel = getTimeTravelStatus(date);
    const memberStats = [
        {label: "Adult", value: members.adult},
        {label: "Child", value: members.child},
        {label: "Concession", value: members.concession},
        {label: "Student", value: members.student},
        {label: "Carer", value: members.carer},
        {label: "Additional Adult", value: members.additionalAdult},
        {label: "Additional Child", value: members.additionalChild},
        {label: "Additional Concession", value: members.additionalConcession},
        {label: "Additional Student", value: members.additionalStudent},
    ];
    return (
        <>
            <GSCNumberPhrase
                number={totalMembers.toLocaleString()}
                phrase={
                    timetravel === "today"
                        ? "Science Passports currently valid"
                        : "Science Passports were valid on this date"
                }
            />
            <GSCNumbersDetails
                timetravel={timetravel}
                stats={memberStats}
                expanded={expanded}
            />
        </>
    );
};
