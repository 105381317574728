/** @format */

import {parseISO, compareAsc} from "date-fns";

const sortPastToFutureOnISODates = (numbersA, numbersB) => {
    const dateA = parseISO(`${numbersA.date}T00:00:00`);
    const dateB = parseISO(`${numbersB.date}T00:00:00`);
    return compareAsc(dateA, dateB);
};

export default sortPastToFutureOnISODates;
