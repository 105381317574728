/** @format */

import React from "react";
import {Grid, Typography} from "@material-ui/core";
import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import GSCWrappedLoading from "../general/WrappedLoading";
import GammaQLDataFetchError from "../general/DataFetchError";
import GSCDateHeader from "../general/DateHeader";
import SingleEvent from "./SingleEvent";
import NavIconButton from "../general/NavIconButton";
import BackIcon from "@material-ui/icons/ArrowBack";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        margin: theme.spacing(1, 0),
        padding: theme.spacing(1),
        [theme.breakpoints.up("md")]: {
            padding: theme.spacing(2, 4),
        },
    },
    sectionTitle: {
        padding: theme.spacing(0),
    },
    singleEventContainer: {
        width: "100%",
    },
}));

const GET_EVENT = gql`
    query getCorporateEvent($bookingReference: String!) {
        corporateEvents: corporateEvents(bookingReference: $bookingReference) {
            masterBookId
            eventName
            eventAttendees
            eventStart
            eventEnd
            eventManager
            opsManager
            bookingStatus
            resourceName
            bookingCategory
            eventType
            eventNotes {
                general
                timetable
                staffing
                setup
                av
                facilities
                catering
                beverage
                cleaning
                internal
                additional
                billing
            }
            spaces {
                linkedBookId
                spaceName
                spaceType
                spaceAttendees
                spaceStart
                spaceEnd
                area
                roomStyle
                spaceNotes {
                    general
                    timetable
                    staffing
                    setup
                    av
                    facilities
                    catering
                    beverage
                    cleaning
                    internal
                    additional
                    billing
                }
            }
            lastSync
        }
    }
`;

const CorporateEvent = ({bookingReference}) => {
    const classes = useStyles();
    const {loading, error, data, refetch} = useQuery(GET_EVENT, {
        variables: {bookingReference},
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "cache-and-network",
    });

    if (loading)
        return (
            <GSCWrappedLoading heading={true}>
                <Typography variant="h5" component="h4">
                    Corporate Events
                </Typography>
            </GSCWrappedLoading>
        );
    if (error || !Array.isArray(data.corporateEvents))
        return (
            <GammaQLDataFetchError
                theThing="Corporate Events"
                showHeader
                refetch={refetch}
            />
        );

    if (data.corporateEvents.length !== 1)
        return (
            <Grid container direction="column" justify="center">
                <GSCDateHeader>Corporate Events</GSCDateHeader>
                <Grid item xs={12} md={10}>
                    <Typography variant="body1">
                        No event details are available for this booking
                        reference
                    </Typography>
                </Grid>
            </Grid>
        );

    const [event] = data.corporateEvents;

    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.gridContainer}
        >
            <Grid
                item
                xs={12}
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
            >
                <NavIconButton
                    icon={<BackIcon />}
                    to="/corporate"
                    label="navigate back"
                />
                <Typography
                    variant="h5"
                    component="h4"
                    className={classes.sectionTitle}
                >
                    Corporate Events
                </Typography>
            </Grid>
            <Grid item xs={12} className={classes.singleEventContainer}>
                <SingleEvent event={event} refetch={refetch} />
            </Grid>
        </Grid>
    );
};

export default CorporateEvent;
