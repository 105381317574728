/** @format */

import React from "react";
import {Card, Grid, Button, Collapse} from "@material-ui/core";
import ExpandIcon from "@material-ui/icons/ExpandMore";
import CollapseIcon from "@material-ui/icons/ExpandLess";
import {SingleDayBar, FooterTotal} from "./subComponents";
import {useDispatch} from "react-redux";
import {push} from "connected-react-router";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    card: {
        width: "100%",
        border: `1px solid ${theme.gsc.navy}`,
        [theme.breakpoints.down("sm")]: {
            marginTop: theme.spacing(2),
        },
    },
    rootContainer: {
        padding: theme.spacing(2, 3),
    },
    expansionButtons: {
        margin: theme.spacing(2, 1, 0, 1),
    },
    collapse: {
        width: "100%",
    },
}));

const GSCVisitorChartCard = ({
    numbers,
    numberOfDataPoints,
    alwaysExpanded,
    reverse = true,
    dateStyle = "Day",
    barChartMaximum = false,
    totalise = false,
    showComparisons = false,
    comparisonData = null,
    deepLinks = true,
    timeUnitsForLink,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    if (!barChartMaximum)
        barChartMaximum = Math.max(...numbers.map((day) => day.total));
    const [expanded, setExpanded] = React.useState(false);
    const expandIcon = expanded ? <CollapseIcon /> : <ExpandIcon />;
    const numbersOrdered = reverse ? numbers.slice().reverse() : numbers;

    if (numbers.length !== numberOfDataPoints) return null;
    const lastThreeDays = numbersOrdered.slice(0, 3);
    const otherDays = numbersOrdered.slice(3);
    const initialDisplayDays = alwaysExpanded ? numbersOrdered : lastThreeDays;

    return (
        <Card variant="outlined" className={classes.card}>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="flex-start"
                className={classes.rootContainer}
            >
                <Grid
                    item
                    xs={12}
                    container
                    direction="column"
                    justify="center"
                    alignItems="flex-start"
                >
                    {initialDisplayDays.map((day, index) => (
                        <SingleDayBar
                            total={day.total}
                            date={day.date}
                            key={`chart-${day.date}`}
                            dateStyle={dateStyle}
                            max={barChartMaximum}
                            showComparison={showComparisons}
                            comparisonTotal={
                                comparisonData ? comparisonData[index] : null
                            }
                            deepLinks={deepLinks}
                            timeUnitsForLink={timeUnitsForLink}
                        />
                    ))}
                </Grid>
                {!alwaysExpanded && (
                    <>
                        <Collapse
                            in={expanded}
                            classes={{container: classes.collapse}}
                        >
                            <Grid
                                item
                                xs={12}
                                container
                                direction="column"
                                justify="center"
                                alignItems="flex-start"
                            >
                                {otherDays.map((day, index) => (
                                    <SingleDayBar
                                        total={day.total}
                                        date={day.date}
                                        key={`chart-${day.date}`}
                                        dateStyle={dateStyle}
                                        max={barChartMaximum}
                                        showComparison={showComparisons}
                                        comparisonTotal={
                                            comparisonData
                                                ? comparisonData[index]
                                                : null
                                        }
                                        deepLinks={deepLinks}
                                        timeUnitsForLink={timeUnitsForLink}
                                    />
                                ))}
                            </Grid>
                        </Collapse>
                        <Grid
                            item
                            xs={12}
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                        >
                            <Button
                                variant="text"
                                color="secondary"
                                onClick={() => setExpanded(!expanded)}
                                className={classes.expansionButtons}
                                endIcon={expandIcon}
                            >
                                Show {expanded ? 3 : numberOfDataPoints} Days
                            </Button>
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={() => dispatch(push("/numbers"))}
                                className={classes.expansionButtons}
                            >
                                More Visitor Numbers
                            </Button>
                        </Grid>
                    </>
                )}
            </Grid>
            {totalise && (
                <FooterTotal
                    numbers={numbersOrdered}
                    showComparison={showComparisons}
                    comparisonData={comparisonData}
                />
            )}
        </Card>
    );
};

export default GSCVisitorChartCard;
