/** @format */

import React from "react";
import {Grid, Typography, Button} from "@material-ui/core";
import {useQuery, useMutation} from "@apollo/react-hooks";
import gql from "graphql-tag";
import GSCWrappedLoading from "../general/WrappedLoading";
import GammaQLDataFetchError from "../general/DataFetchError";
import GSCVisitorChartCard from "../visitorchart";
import {
    format,
    isFuture,
    isWithinInterval,
    differenceInCalendarDays,
} from "date-fns";
import {groupNumbers, getStartDate, getEndDate} from "./comparePanelHelpers";
import sortPastToFutureOnISODates from "../../utils/sortPastToFutureOnISODates";
import {maxBarChartVisitors, apiFormat} from "../../config";

const TEN_MINUTES = 10 * 60 * 1000;

const GET_NUMBERS = gql`
    query visitorNumbers($from: String!, $to: String!) {
        visitorNumbers(fromDate: $from, toDate: $to) {
            date
            total
            lastSync
        }
    }
`;

const SYNC_NUMBERS = (date) => {
    if (isFuture(date))
        return gql`
            mutation futureNumbersSync($from: String!, $to: String!) {
                syncPrebookersFromGamma(startDate: $from, endDate: $to) {
                    date
                }
            }
        `;
    return gql`
        mutation allNumbersSync($from: String!, $to: String!) {
            syncPrebookersFromGamma(startDate: $from, endDate: $to) {
                date
            }
            syncNumbersFromGamma(startDate: $from, endDate: $to) {
                date
            }
        }
    `;
};

const GSCComparePanel = ({
    date,
    type,
    fixedTimeframe,
    timeUnitsEitherSide,
    comparativeMaximum,
    testComparativeMaximum,
    showComparisons = false,
    comparisonData,
    useToSetComparisons = false,
    comparisonsAreSet = true,
    setComparisonData,
    deepLinks = true,
}) => {
    const from = getStartDate(date, type, fixedTimeframe, timeUnitsEitherSide);
    const to = getEndDate(date, type, fixedTimeframe, timeUnitsEitherSide);

    const totalNumberOfUnits = fixedTimeframe
        ? 12
        : 2 * timeUnitsEitherSide + 1;
    const expectedNumberOfResults = differenceInCalendarDays(to, from) + 1;
    const todayIsInRange = isWithinInterval(new Date(), {start: from, end: to});
    const pollInterval = todayIsInRange ? TEN_MINUTES : 0;
    const {
        loading: queryLoading,
        error: queryError,
        data,
        refetch,
    } = useQuery(GET_NUMBERS, {
        variables: {from: apiFormat(from), to: apiFormat(to)},
        notifyOnNetworkStatusChange: true,
        pollInterval,
        fetchPolicy: "cache-and-network",
    });
    const [
        triggerNumbersSync,
        {loading: mutationLoading, error: mutationError},
    ] = useMutation(SYNC_NUMBERS(date), {
        update: () => refetch(),
    });

    if (queryLoading || mutationLoading)
        return <GSCWrappedLoading date={date} mightBeLong={mutationLoading} />;
    if (queryError || !Array.isArray(data.visitorNumbers))
        return (
            <GammaQLDataFetchError theThing="Visitor stats" refetch={refetch} />
        );
    if (mutationError)
        return (
            <Grid container justify="center" alignItems="center">
                <Typography variant="body1">
                    Could not retrieve a result from Gamma
                </Typography>
            </Grid>
        );

    if (data.visitorNumbers.length !== expectedNumberOfResults)
        return (
            <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="center"
            >
                <Grid item xs={12}>
                    <Typography variant="body1">
                        Visitor numbers are not yet available for the date range{" "}
                        {format(from, "dd MMMM yyyy")} to{" "}
                        {format(to, "dd MMMM yyyy")}.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => {
                            triggerNumbersSync({
                                variables: {from, to},
                            });
                        }}
                    >
                        Get Visitor Numbers from Gamma
                    </Button>
                </Grid>
            </Grid>
        );

    const sortedNumbers = data.visitorNumbers
        .slice()
        .sort(sortPastToFutureOnISODates);
    const groupedNumbers = groupNumbers(type, sortedNumbers);
    const groupedNumbersValues = groupedNumbers.map((day) => day.total);
    if (useToSetComparisons && !comparisonsAreSet)
        setComparisonData(groupedNumbersValues);

    const thisDataMaximum =
        type === "Day"
            ? Math.max(...groupedNumbersValues, maxBarChartVisitors)
            : Math.max(...groupedNumbersValues);
    const barChartMaximum =
        thisDataMaximum > comparativeMaximum
            ? testComparativeMaximum(thisDataMaximum)
            : comparativeMaximum;

    return (
        <GSCVisitorChartCard
            numbers={groupedNumbers}
            numberOfDataPoints={totalNumberOfUnits}
            alwaysExpanded={true}
            reverse={false}
            dateStyle={type}
            barChartMaximum={barChartMaximum}
            totalise
            showComparisons={showComparisons}
            comparisonData={comparisonData}
            deepLinks={deepLinks}
        />
    );
};

export default GSCComparePanel;
