/** @format */

import React from "react";
import RouteMapper from "./RouteMapper";
import SingleDate from "../components/root/SingleDate";

const routes = [
    {
        path: "/:date+",
        render: () => <SingleDate />,
    },
];

const DateRoute = ({match}) => (
    <RouteMapper routes={routes} rootPath={match.path} defaultRoute="/today" />
);

export default DateRoute;
