/** @format */

import React from "react";
import {Tooltip, Typography} from "@material-ui/core";
import useInterval from "../../utils/useInterval";
import {format, isToday, isYesterday, formatDistanceToNow} from "date-fns";
import dateify from "../../utils/dateify";

const calcWords = (dateArg) =>
    formatDistanceToNow(dateArg, {includeSeconds: true, addSuffix: true});

const calcDisplayTime = (checkDate) => {
    if (isToday(checkDate)) return `Today @ ${format(checkDate, "HH:mm")}`;
    if (isYesterday(checkDate))
        return `Yesterday @ ${format(checkDate, "HH:mm")}`;
    return format(checkDate, "EEE d MMM yyyy, HH:mm");
};

const LastUpdatedTooltip = ({
    placement = "top",
    introText = "Last updated",
    lastSync = null,
} = {}) => {
    const ONE_MINUTE = 60 * 1000;
    const [words, setWords] = React.useState();
    const [displayTime, setDisplayTime] = React.useState();
    const recalculate = () => {
        const lastSyncDate = dateify(lastSync);
        setWords(calcWords(lastSyncDate));
        setDisplayTime(calcDisplayTime(lastSyncDate));
    };
    React.useEffect(recalculate, [lastSync]);
    useInterval(recalculate, ONE_MINUTE);

    return (
        <Tooltip
            placement={placement}
            arrow
            title={<Typography variant="body2">{displayTime}</Typography>}
        >
            <Typography variant="caption">
                {introText} {words}
            </Typography>
        </Tooltip>
    );
};

export default LastUpdatedTooltip;
