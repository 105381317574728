/** @format */

import React from "react";
import RouteMapper from "../RouteMapper";
import CompareRoute from "./CompareRoute";

const routes = [
    {
        path: "/week",
        render: ({match}) => <CompareRoute match={match} />,
    },
    {
        path: "/month",
        render: ({match}) => <CompareRoute match={match} />,
    },
    {
        path: "/year",
        render: ({match}) => <CompareRoute match={match} />,
    },
];

const NumbersRoute = ({match}) => (
    <RouteMapper routes={routes} rootPath={match.path} defaultRoute="/week" />
);

export default NumbersRoute;
