/** @format */

import React from "react";
import {
    Grid,
    Card,
    CardContent,
    Collapse,
    Button,
    Typography,
} from "@material-ui/core";
import GSCSingleEventCard from "./SingleEventCard";
import LastUpdatedTooltip from "../general/LastUpdatedTooltip";
import RefetchButton from "../general/RefetchButton";
import {parseISO, compareAsc} from "date-fns";
import {makeStyles} from "@material-ui/core/styles";
import {categories, towerWeatherUrl} from "../../config";

const useStyles = makeStyles((theme) => ({
    eventsContainer: {
        width: "100%",
        margin: theme.spacing(1),
    },
    eventsContainerCard: ({categoryColour}) => ({
        border: `1px solid ${theme.gsc[categoryColour]}`,
    }),
    eventsContainerHeader: ({categoryColour, isExpanded}) => ({
        borderBottom: isExpanded
            ? `1px solid ${theme.gsc[categoryColour]}`
            : "none",
        padding: theme.spacing(3),
        cursor: "pointer",
    }),
    eventsContainerBody: {
        backgroundColor: theme.gsc.offwhite,
    },
    eventsContainerFooter: ({categoryColour}) => ({
        borderTop: `1px solid ${theme.gsc[categoryColour]}`,
        padding: theme.spacing(1, 2),
        backgroundColor: theme.gsc.offwhite,
    }),
}));

const getTotalVisitors = (category, isSubcategory, eventsInCategory) => {
    const numberField =
        !isSubcategory && categories[category].nonGamma
            ? category === "Corporate"
                ? "eventAttendees"
                : "People"
            : "TotalBooked";
    const reducer = (accumulator, singleEvent) =>
        accumulator + singleEvent[numberField];

    if (category === "Lunch")
        return eventsInCategory
            .filter((event) => /.*Lunch.*/.test(event.ResName))
            .reduce(reducer, 0);
    if (category === "SEC Events" || category === "Ibrox") return 0;
    return eventsInCategory.reduce(reducer, 0);
};

const sortOnStartTime = (eventA, eventB) => {
    const extractDateTime = (event) =>
        event.StartTime
            ? parseISO(`${event.ResDate}T${event.StartTime}`)
            : event.DateTimeStart
            ? parseISO(event.DateTimeStart)
            : new Date(Number(event.eventStart));
    const timeA = extractDateTime(eventA);
    const timeB = extractDateTime(eventB);
    return compareAsc(timeA, timeB);
};

const shouldSkipWholeCategory = (category, isSubcategory, eventsInCategory) => {
    if (eventsInCategory.length === 0) return true;
    if (
        category === "Education" &&
        // Only lunch events are present
        eventsInCategory.filter((event) => event.subcategory !== "Lunch")
            .length !== 0 &&
        getTotalVisitors(category, isSubcategory, eventsInCategory) === 0
    )
        return true;
    return false;
};

const GSCEventsHeader = ({category, isSubcategory, total}) => (
    <Grid container direction="row" justify="space-between" alignItems="center">
        {isSubcategory ? (
            <Typography variant="h5" component="h6">
                {category}
            </Typography>
        ) : (
            <Typography variant="h4" component="h5">
                {categories[category].displayName}
            </Typography>
        )}
        {total !== 0 && <Typography variant="body1">{total}</Typography>}
    </Grid>
);

const GSCEventsList = ({events, category, categoryColour}) => {
    return (
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
        >
            {events.map((event) => {
                const id = event.SyncID || event.masterBookId || event.EventID;
                return (
                    <GSCSingleEventCard
                        key={id}
                        event={event}
                        category={category}
                        colour={categoryColour}
                    />
                );
            })}
        </Grid>
    );
};

const GSCEventsContainer = ({
    events,
    category,
    categoryColour,
    hasSubcategories,
    isSubcategory,
    refetch,
}) => {
    const [isExpanded, setIsExpanded] = React.useState(false);
    const toggle = () => setIsExpanded(!isExpanded);
    const classes = useStyles({categoryColour, isExpanded});

    const categoryKey = category.replace(/\s/g, "");
    const lastSync =
        events[0].lastSync ||
        Math.max(...events.map((event) => event.lastUpdate));

    return (
        <Grid key={categoryKey} className={classes.eventsContainer}>
            <Card variant="outlined" className={classes.eventsContainerCard}>
                <CardContent
                    onClick={toggle}
                    className={classes.eventsContainerHeader}
                >
                    <GSCEventsHeader
                        category={category}
                        isSubcategory={isSubcategory}
                        total={
                            isSubcategory || !hasSubcategories
                                ? getTotalVisitors(
                                      category,
                                      isSubcategory,
                                      events
                                  )
                                : 0
                        }
                    />
                </CardContent>
                <Collapse in={isExpanded}>
                    <CardContent className={classes.eventsContainerBody}>
                        {hasSubcategories ? (
                            <GSCEventCategories
                                categoryArray={
                                    categories[category].subcategories
                                }
                                events={events}
                                isSubcategory={true}
                                categoryColour={categoryColour}
                                refetch={refetch}
                            />
                        ) : (
                            <GSCEventsList
                                events={events}
                                category={category}
                                categoryColour={categoryColour}
                                refetch={refetch}
                            />
                        )}
                        {category === "Tower" && (
                            <Grid
                                container
                                justify="center"
                                alignItems="center"
                            >
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    href={towerWeatherUrl}
                                    target="_blank"
                                    rel="noopener nofollow"
                                >
                                    Check Current Wind Speed
                                </Button>
                            </Grid>
                        )}
                    </CardContent>
                    {(isSubcategory || !hasSubcategories) && (
                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="center"
                            className={classes.eventsContainerFooter}
                        >
                            <LastUpdatedTooltip
                                id={categoryKey}
                                lastSync={lastSync}
                            />
                            <RefetchButton onClick={refetch} />
                        </Grid>
                    )}
                </Collapse>
            </Card>
        </Grid>
    );
};

const GSCEventCategories = ({
    categoryArray,
    events,
    isSubcategory,
    categoryColour,
    refetch,
}) => {
    return categoryArray.map((category) => {
        const categoryColourIndex = categoryColour
            ? categoryColour
            : categories[category].colourMapping;
        const eventsInCategory = events
            .filter((event) => {
                const comparator = isSubcategory
                    ? event.SubCategory
                    : event.Category;
                return comparator === category;
            })
            .sort(sortOnStartTime);
        if (shouldSkipWholeCategory(category, isSubcategory, eventsInCategory))
            return null;
        const hasSubcategories =
            !isSubcategory && Boolean(categories[category].subcategories);
        return (
            <GSCEventsContainer
                key={category}
                category={category}
                categoryColour={categoryColourIndex}
                events={eventsInCategory}
                hasSubcategories={hasSubcategories}
                isSubcategory={isSubcategory}
                refetch={refetch}
            />
        );
    });
};

const GSCCategoryWrapper = ({events, refetch}) => (
    <GSCEventCategories
        categoryArray={Object.keys(categories)}
        events={events}
        isSubcategory={false}
        refetch={refetch}
    />
);

export default GSCCategoryWrapper;
