/** @format */

import React from "react";
import {Text, View, StyleSheet} from "@react-pdf/renderer";
import {format} from "date-fns";

const styles = StyleSheet.create({
    section: {
        margin: 10,
        padding: 5,
    },
    pageFooter: {
        width: "100%",
        position: "absolute",
        bottom: 0,
        margin: 5,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    footerText: {
        margin: 2,
        fontFamily: "Arial",
        fontSize: "8pt",
    },
});

const PageFooter = ({event}) => (
    <View style={[styles.section, styles.pageFooter]} fixed>
        <Text style={styles.footerText}>
            Last Gamma update at{" "}
            {format(Number(event.lastSync), "HH:mm' on 'dd/MM/yy")}
        </Text>
        <Text
            style={styles.footerText}
            render={({pageNumber, totalPages}) =>
                `Page ${pageNumber} / ${totalPages}`
            }
            fixed
        />
        <Text style={styles.footerText}>
            Printed from GSC Today at{" "}
            {format(new Date(), "HH:mm' on 'dd/MM/yy")}
        </Text>
    </View>
);

export default PageFooter;
