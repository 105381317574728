/** @format */

import {combineReducers} from "redux";
import {connectRouter} from "connected-react-router";
import selectedDateReducer from "./selectedDateSlice";
import corpDateRangeReducer from "./corpDateRangeSlice";
import corpStatusFilterReducer from "./corpStatusFilterSlice";
import corpResourceFilterReducer from "./corpResourceFilterSlice";

const createRootReducer = (history) =>
    combineReducers({
        router: connectRouter(history),
        selectedDate: selectedDateReducer,
        corpDateRange: corpDateRangeReducer,
        corpStatusFilter: corpStatusFilterReducer,
        corpResourceFilter: corpResourceFilterReducer,
    });

export default createRootReducer;
