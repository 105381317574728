/** @format */

import {useEffect, useRef} from "react";

// See https://overreacted.io/making-setinterval-declarative-with-react-hooks/
const useReset = (setter, value, comparator, delay) => {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = () => setter(value);
    }, [setter, value]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null && value !== comparator) {
            let id = setTimeout(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay, value, comparator]);
};

export default useReset;
