/** @format */

import React from "react";
import {Grid, Typography} from "@material-ui/core";
import GSCDatePicker from "../general/Datepicker";
import {format} from "date-fns";

const GSCDateHeader = ({
    date = null,
    rootPath = null,
    variant = "h4",
    component = "h2",
    justify = "flex-start",
    children,
} = {}) => {
    const content = date ? (
        <>
            <Typography variant={variant} component={component}>
                {format(date, "EEEE do MMMM yyyy")}
            </Typography>
            <GSCDatePicker rootPath={rootPath || undefined} />
        </>
    ) : (
        children
    );
    return (
        <Grid container justify={justify}>
            <Grid
                item
                xs={12}
                container
                direction="row"
                justify={justify}
                alignItems="center"
            >
                {content}
            </Grid>
        </Grid>
    );
};

export default GSCDateHeader;
