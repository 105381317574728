/** @format */

import React from "react";
import {Text, View, StyleSheet} from "@react-pdf/renderer";
import {format} from "date-fns";
import {getEventSpacesByDate} from "../../../utils/corpEventFilters";

const styles = StyleSheet.create({
    section: {
        margin: "2 6",
        padding: 5,
    },
    rowContainer: {
        margin: 5,
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    columnContainer: {
        margin: 5,
        flexDirection: "column",
        justifyContent: "space-between",
    },
    heading: {
        fontFamily: "ProximaNova",
        color: "#2e2d44",
        margin: "3 0",
    },
    h1size: {
        fontSize: "24pt",
    },
    h2size: {
        fontSize: "20pt",
    },
    h3size: {
        fontSize: "16pt",
    },
    bodyText: {
        margin: 1,
        fontFamily: "Arial",
        fontSize: "10pt",
    },
    bold: {
        fontWeight: "bold",
    },
    tableRowContainer: {
        margin: "1 3",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    tableCell: {
        margin: 1,
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "21%",
    },
    tableCellNarrow: {
        margin: 1,
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "12%",
    },
    tableCellExtraNarrow: {
        margin: 1,
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "5%",
    },
});

const tableColumnNames = [
    "Name",
    "Purpose",
    "No's",
    "Times",
    "Location",
    "Style",
];
const tableColumns = [
    "spaceName",
    "spaceType",
    "spaceAttendees",
    "Times",
    "area",
    "roomStyle",
];

const TableHeaderText = ({children, narrow}) => (
    <View
        style={
            narrow === "No's"
                ? styles.tableCellExtraNarrow
                : narrow === "Times"
                ? styles.tableCellNarrow
                : styles.tableCell
        }
    >
        <Text style={[styles.bodyText, styles.bold]}>{children}</Text>
    </View>
);
const TableBodyText = ({children, narrow}) => (
    <View
        style={
            narrow === "spaceAttendees"
                ? styles.tableCellExtraNarrow
                : narrow === "Times"
                ? styles.tableCellNarrow
                : styles.tableCell
        }
    >
        <Text style={styles.bodyText}>{children}</Text>
    </View>
);

const SpacesTableRow = ({space}) => (
    <View style={styles.tableRowContainer}>
        {tableColumns.map((col) => (
            <TableBodyText
                key={col}
                narrow={
                    col === "spaceAttendees" || col === "Times" ? col : false
                }
            >
                {col === "Times"
                    ? `${format(Number(space.spaceStart), "HH:mm")}–${format(
                          Number(space.spaceEnd),
                          "HH:mm"
                      )}`
                    : space[col]}
            </TableBodyText>
        ))}
    </View>
);

const SpacesTable = ({event}) => {
    const {eventDates, eventSpacesByDate} = getEventSpacesByDate(event);
    return (
        <View style={styles.section}>
            <Text style={[styles.heading, styles.h2size]} minPresenceAhead={50}>
                Spaces
            </Text>
            {eventDates.map(
                (eventDate, idx) =>
                    Boolean(eventSpacesByDate[idx].length) && (
                        <View key={eventDate} style={styles.columnContainer}>
                            <Text
                                style={[styles.heading, styles.h3size]}
                                minPresenceAhead={30}
                            >
                                {format(eventDate, "EEEE do MMMM")}
                            </Text>
                            <View style={styles.tableRowContainer}>
                                {tableColumnNames.map((column) => (
                                    <TableHeaderText
                                        key={column}
                                        narrow={
                                            column === "No's" ||
                                            column === "Times"
                                                ? column
                                                : false
                                        }
                                    >
                                        {column}
                                    </TableHeaderText>
                                ))}
                            </View>
                            {eventSpacesByDate[idx].map((space) => (
                                <SpacesTableRow
                                    key={space.linkedBookId}
                                    space={space}
                                />
                            ))}
                        </View>
                    )
            )}
        </View>
    );
};

export default SpacesTable;
