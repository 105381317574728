/** @format */

import React from "react";
import {Grid, List} from "@material-ui/core";
import EventListItem from "./EventListItem";
import PeopleIcon from "@material-ui/icons/People";
import StartIcon from "@material-ui/icons/AccessTime";
import EndIcon from "@material-ui/icons/WatchLater";
import CategoryIcon from "@material-ui/icons/Category";
import EventManagerIcon from "@material-ui/icons/RecordVoiceOver";
import OpsManagerIcon from "@material-ui/icons/Restaurant";
import BookingIcon from "@material-ui/icons/Bookmark";
import {getCorpEventType} from "../../utils/corpEventFilters";
import {format} from "date-fns";

const EventDetailsList = ({event}) => {
    const combinedCategoryAndType = getCorpEventType(event);
    return (
        <Grid container direction="row">
            <Grid item xs={12} sm={6}>
                <List dense>
                    <EventListItem
                        primary={format(
                            Number(event.eventStart),
                            "EEEE do MMMM yyyy, HH:mm"
                        )}
                        secondary="Start Time"
                        icon={<StartIcon />}
                    />
                    <EventListItem
                        primary={format(
                            Number(event.eventEnd),
                            "EEEE do MMMM yyyy, HH:mm"
                        )}
                        secondary="End Time"
                        icon={<EndIcon />}
                    />
                    <EventListItem
                        primary={event.eventAttendees}
                        secondary="Attendees"
                        icon={<PeopleIcon />}
                    />
                    <EventListItem
                        primary={combinedCategoryAndType}
                        secondary="Event Type"
                        icon={<CategoryIcon />}
                    />
                </List>
            </Grid>
            <Grid item xs={12} sm={6}>
                <List dense>
                    <EventListItem
                        primary={event.bookingStatus}
                        secondary="Booking Status"
                        status={event.bookingStatus}
                    />
                    <EventListItem
                        primary={event.eventManager}
                        secondary="Event Manager"
                        icon={<EventManagerIcon />}
                    />
                    <EventListItem
                        primary={event.opsManager}
                        secondary="Ops Manager"
                        icon={<OpsManagerIcon />}
                    />
                    <EventListItem
                        primary={event.masterBookId}
                        secondary="Booking Reference"
                        icon={<BookingIcon />}
                    />
                </List>
            </Grid>
        </Grid>
    );
};

export default EventDetailsList;
