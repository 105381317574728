/** @format */

import React from "react";
import {GSCAuthConsumer, isLoggedIn} from ".";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    userImg: ({size, displaySize}) => ({
        width: displaySize ? `${displaySize}px` : `${size}px`,
        maxWidth: "100%",
        borderRadius: "50%",
    }),
}));

const UserPic = ({size, displaySize}) => {
    const classes = useStyles({size, displaySize});
    return (
        <GSCAuthConsumer>
            {({auth}) => {
                return isLoggedIn() && auth.pics && auth.pics[size] ? (
                    <img
                        src={auth.pics[size]}
                        alt="User avatar"
                        className={classes.userImg}
                    />
                ) : (
                    <AccountCircleIcon />
                );
            }}
        </GSCAuthConsumer>
    );
};

export default UserPic;
