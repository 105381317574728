/** @format */

import {format, isToday, isTomorrow, isYesterday} from "date-fns";

const specialDates = (date) => {
    if (!date) return false;
    if (isToday(date)) return "today";
    if (isTomorrow(date)) return "tomorrow";
    if (isYesterday(date)) return "yesterday";
    return false;
};

export const getCanonicalRoute = (rootPath, day) => {
    try {
        const isSpecialDate = specialDates(day);
        const toPath = isSpecialDate
            ? `${rootPath}/${isSpecialDate}`
            : format(day, `'${rootPath}/'yyyy'/'MM'/'dd`);
        return toPath;
    } catch (e) {
        return "";
    }
};
