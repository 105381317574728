/** @format */

import React from "react";
import RouteMapper from "../RouteMapper";
import CompareTabs from "../../components/root/CompareTabs";

const routes = (activeTab) => [
    {
        path: "/:date+",
        render: () => <CompareTabs activeTab={activeTab} />,
    },
];

const CompareRoute = ({match}) => {
    const activeTab = match.path.split("/").slice().reverse()[0];
    return (
        <RouteMapper
            routes={routes(activeTab)}
            rootPath={match.path}
            defaultRoute="/today"
        />
    );
};

export default CompareRoute;
