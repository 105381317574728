/** @format */

import {createStore, applyMiddleware, compose} from "redux";
import {persistStore, persistReducer} from "redux-persist";
import localForage from "localforage";
import {routerMiddleware} from "connected-react-router";
import {createBrowserHistory} from "history";
import createRootReducer from "./rootReducer";
import {storeRootString} from "../config";

export const history = createBrowserHistory();

const persistConfig = {
    key: storeRootString,
    storage: localForage,
    blacklist: ["router", "selectedDate"],
};

const rootReducer = createRootReducer(history);
const persistedReducer = persistReducer(persistConfig, rootReducer);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(routerMiddleware(history)))
);
export const persistor = persistStore(store);

export default store;
