/** @format */

import useInterval from "./useInterval";
import {useSelector, useDispatch} from "react-redux";
import {getLocation} from "connected-react-router";
import {
    dateSelector,
    getDateFromPath,
    selectedDateActions,
} from "../redux/selectedDateSlice";
import {isSameDay} from "date-fns";

const useAutoAdvance = () => {
    const dispatch = useDispatch();
    const selectedDate = useSelector(dateSelector);
    const location = useSelector(getLocation);

    const FIFTEEN_MINUTES = 15 * 60 * 1000;

    useInterval(() => {
        const pathDate = getDateFromPath(location.pathname);
        const needsToAdvance = !isSameDay(pathDate, selectedDate);
        if (needsToAdvance)
            dispatch({
                type: selectedDateActions.forcedUpdate,
                payload: {date: pathDate},
            });
    }, FIFTEEN_MINUTES);
};

export default useAutoAdvance;
