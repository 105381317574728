/** @format */

import {
    format,
    isToday,
    isYesterday,
    isTomorrow,
    isThisISOWeek,
    isThisMonth,
    isThisYear,
    getMonth,
    addDays,
    addWeeks,
} from "date-fns";
import {getCanonicalRoute} from "../../utils/getCanonicalRoute";
import {comparisonPaneConfigs} from "../../config";

export const applySeparator = (number) =>
    number < 10000 ? number : Number(number).toLocaleString();

export const totaliser = (numbers) => {
    const sum = numbers.reduce(
        (accumulator, timeUnit) => accumulator + Number(timeUnit.total),
        0
    );
    return {total: sum, totalDisplay: applySeparator(sum)};
};

export const formatDate = (date, style) => {
    switch (style) {
        case "Year":
            if (isThisMonth(date))
                return `This Month (${format(date, "MMMM")})`;
            if (isThisYear(date) && getMonth(date) > 2)
                return format(date, "MMMM");
            return format(date, "MMMM yyyy");
        case "Week":
            if (isThisISOWeek(date)) return "This Week";
            if (isThisMonth(date)) return format(date, "'w/c' EEE do MMM");
            if (isThisYear(date)) return format(date, "'w/c' EEE do MMM");
            return format(date, "'w/c' EEE do MMM yyyy");
        case "Day":
        default:
            if (isToday(date)) return "Today";
            if (isYesterday(date)) return "Yesterday";
            if (isTomorrow(date)) return "Tomorrow";
            if (isThisISOWeek(date)) return format(date, "EEEE do");
            if (isThisMonth(date)) return format(date, "EEEE do");
            if (isThisYear(date)) return format(date, "EEEE do MMM");
            return format(date, "EEEE do MMM yyyy");
    }
};

export const generateLinkForDateStyle = (date, style) => {
    switch (style) {
        case "Year":
            return getCanonicalRoute(
                "/numbers/month",
                addWeeks(date, comparisonPaneConfigs.month.timeUnitsEitherSide)
            );
        case "Week":
            return getCanonicalRoute(
                "/numbers/week",
                addDays(date, comparisonPaneConfigs.week.timeUnitsEitherSide)
            );
        case "Day":
        default:
            return getCanonicalRoute("/date", date);
    }
};
