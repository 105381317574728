/** @format */

import React from "react";
import {Grid, Button, Typography} from "@material-ui/core";
import GSCComparePanel from "./GSCComparePanel";
import GSCDateHeader from "../general/DateHeader";
import {useDispatch} from "react-redux";
import {push} from "connected-react-router";
import {format, subISOWeekYears, isThisYear} from "date-fns";
import {getCanonicalRoute} from "../../utils/getCanonicalRoute";
import {
    maxBarChartVisitors,
    comparisonPaneConfigs,
    datePickerPastStart,
    datePickerFutureEnd,
} from "../../config";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        margin: theme.spacing(2, 0),
    },
    yearHeading: {
        margin: theme.spacing(2, 0),
    },
    prevButtonContainer: {
        order: 0,
        [theme.breakpoints.down("xs")]: {
            order: 1,
        },
    },
    dateHeadingContainer: {
        order: 1,
        [theme.breakpoints.down("xs")]: {
            order: 0,
        },
    },
    nextButtonContainer: {
        order: 2,
    },
}));

const GSCCompareNumbersPane = ({
    isHidden,
    type,
    date,
    showComparisons,
    deepLinks,
}) => {
    const classes = useStyles();

    const [comparativeMaximum, setComparativeMaximum] =
        React.useState(maxBarChartVisitors);
    const testComparativeMaximum = (maxToTest) => {
        if (maxToTest > comparativeMaximum) setComparativeMaximum(maxToTest);
    };

    const [comparisonData, setComparisonData] = React.useState(false);

    const resetComparisons = () => {
        setComparisonData(false);
        setComparativeMaximum(maxBarChartVisitors);
    };
    React.useEffect(resetComparisons, [date]);

    const lastYearDate = subISOWeekYears(date, 1);
    const thisYear = isThisYear(date);
    const conf = comparisonPaneConfigs[type];

    const dispatch = useDispatch();

    return isHidden ? null : (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.gridContainer}
        >
            <Grid item xs={12} container justify="center" alignItems="center">
                <Grid
                    item
                    xs={6}
                    sm={2}
                    container
                    justify="flex-start"
                    alignItems="center"
                    className={classes.prevButtonContainer}
                >
                    <Button
                        color="secondary"
                        variant="outlined"
                        disabled={
                            conf.timeUnitName === "Year" &&
                            format(lastYearDate, "yyyy") ===
                                format(datePickerPastStart, "yyyy")
                        }
                        onClick={() => {
                            const toRoute = getCanonicalRoute(
                                conf.canonicalRouteRoot,
                                conf.subTimeUnit(date)
                            );
                            dispatch(push(toRoute));
                        }}
                    >
                        -1 {conf.timeUnitName}
                    </Button>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={8}
                    container
                    justify="center"
                    alignItems="center"
                    className={classes.dateHeadingContainer}
                >
                    <GSCDateHeader
                        date={date}
                        rootPath={`/numbers/${type}`}
                        variant="h5"
                        component="h3"
                        justify="center"
                    />
                </Grid>
                <Grid
                    item
                    xs={6}
                    sm={2}
                    container
                    justify="flex-end"
                    alignItems="center"
                    className={classes.nextButtonContainer}
                >
                    <Button
                        color="secondary"
                        variant="outlined"
                        disabled={
                            conf.timeUnitName === "Year" &&
                            format(date, "yyyy") ===
                                format(datePickerFutureEnd, "yyyy")
                        }
                        onClick={() => {
                            const toRoute = getCanonicalRoute(
                                conf.canonicalRouteRoot,
                                conf.addTimeUnit(date)
                            );
                            dispatch(push(toRoute));
                        }}
                    >
                        +1 {conf.timeUnitName}
                    </Button>
                </Grid>
            </Grid>
            <Grid
                item
                xs={12}
                container
                direction="row"
                justify="space-around"
                alignItems="flex-start"
            >
                <Grid
                    item
                    xs={12}
                    md={5}
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="center"
                >
                    <Typography
                        variant="h6"
                        component="h4"
                        className={classes.yearHeading}
                    >
                        {thisYear ? "Last" : "Previous"} Year
                    </Typography>
                    <GSCComparePanel
                        date={lastYearDate}
                        type={conf.timeUnitName}
                        fixedTimeframe={conf.fixedTimeframe}
                        timeUnitsEitherSide={conf.timeUnitsEitherSide}
                        comparativeMaximum={comparativeMaximum}
                        testComparativeMaximum={testComparativeMaximum}
                        useToSetComparisons={true}
                        comparisonsAreSet={Boolean(comparisonData)}
                        setComparisonData={setComparisonData}
                        deepLinks={deepLinks}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={5}
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="center"
                >
                    <Typography
                        variant="h6"
                        component="h4"
                        className={classes.yearHeading}
                    >
                        {thisYear ? "This" : "Selected"} Year
                    </Typography>
                    <GSCComparePanel
                        date={date}
                        type={conf.timeUnitName}
                        fixedTimeframe={conf.fixedTimeframe}
                        timeUnitsEitherSide={conf.timeUnitsEitherSide}
                        comparativeMaximum={comparativeMaximum}
                        testComparativeMaximum={testComparativeMaximum}
                        showComparisons={showComparisons}
                        comparisonData={comparisonData}
                        deepLinks={deepLinks}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default GSCCompareNumbersPane;
