/** @format */

import React from "react";
import {
    Grid,
    Typography,
    FormControlLabel,
    Switch,
    Tab,
    Tabs,
} from "@material-ui/core";
import GSCCompareNumbersPane from "../compare/";
import {useSelector, useDispatch} from "react-redux";
import {dateSelector} from "../../redux/selectedDateSlice";
import {push} from "connected-react-router";
import {getCanonicalRoute} from "../../utils/getCanonicalRoute";
import useAutoAdvance from "../../utils/useAutoAdvance";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {makeStyles, useTheme} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        margin: theme.spacing(1, 0),
        padding: theme.spacing(1),
        [theme.breakpoints.up("md")]: {
            padding: theme.spacing(2, 4),
        },
    },
    tabs: {
        width: "100%",
        margin: theme.spacing(1, 0, 2, 0),
    },
}));

const LinkTab = (props) => {
    const dispatch = useDispatch();
    const {to, ...otherProps} = props;
    return (
        <Tab
            onClick={(event) => {
                event.preventDefault();
                dispatch(push(to));
            }}
            {...otherProps}
        />
    );
};

const CompareTabs = ({activeTab}) => {
    const classes = useStyles();
    const date = useSelector(dateSelector);
    const [showComparisons, setShowComparisons] = React.useState(false);
    const toggleComparisons = (event) =>
        setShowComparisons(event.target.checked);
    const theme = useTheme();
    const isMinSmScreen = useMediaQuery(theme.breakpoints.up("sm"));
    useAutoAdvance();
    return (
        <Grid
            item
            xs={12}
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.gridContainer}
        >
            <Grid item xs={12} container>
                <Grid
                    item
                    xs={12}
                    sm={8}
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                >
                    <Typography variant="h4" component="h2">
                        Visitor Numbers
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={4}
                    container
                    direction="row"
                    justify={isMinSmScreen ? "flex-end" : "flex-start"}
                    alignItems="center"
                >
                    <FormControlLabel
                        control={
                            <Switch
                                checked={showComparisons}
                                onChange={toggleComparisons}
                                value="Show Comparisons"
                                color="secondary"
                            />
                        }
                        label="Show Comparisons"
                        labelPlacement={isMinSmScreen ? "start" : "end"}
                    />
                </Grid>
            </Grid>
            <Tabs
                variant="fullWidth"
                value={activeTab}
                aria-label="navigation tabs"
                className={classes.tabs}
            >
                <LinkTab
                    value="week"
                    label="By Week"
                    to={getCanonicalRoute("/numbers/week", date)}
                />
                <LinkTab
                    value="month"
                    label="By Month"
                    to={getCanonicalRoute("/numbers/month", date)}
                />
                <LinkTab
                    value="year"
                    label="By Year"
                    to={getCanonicalRoute("/numbers/year", date)}
                />
            </Tabs>
            <GSCCompareNumbersPane
                isHidden={activeTab !== "week"}
                type="week"
                date={date}
                showComparisons={showComparisons}
                deepLinks={true}
            />
            <GSCCompareNumbersPane
                isHidden={activeTab !== "month"}
                type="month"
                date={date}
                showComparisons={showComparisons}
                deepLinks={true}
            />
            <GSCCompareNumbersPane
                isHidden={activeTab !== "year"}
                type="year"
                date={date}
                showComparisons={showComparisons}
                deepLinks={true}
            />
        </Grid>
    );
};

export default CompareTabs;
