/** @format */

import React from "react";
import {Grid, Button, Typography} from "@material-ui/core";
import {useQuery, useMutation} from "@apollo/react-hooks";
import gql from "graphql-tag";
import GSCWrappedLoading from "../general/WrappedLoading";
import GammaQLDataFetchError from "../general/DataFetchError";
import GSCDateHeader from "../general/DateHeader";
import RefetchButton from "../general/RefetchButton";
import GSCCategoryWrapper from "./EventCategories";
import {useSelector} from "react-redux";
import {corpStatusFilterSelector} from "../../redux/corpStatusFilterSlice";
import {filterByStatusDisplay} from "../../utils/corpEventFilters";
import {isToday} from "date-fns";
import {makeStyles} from "@material-ui/core/styles";
import {apiFormat} from "../../config";

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        margin: theme.spacing(3, 0),
    },
    categoryContainer: {
        width: "100%",
    },
}));

const TEN_MINUTES = 10 * 60 * 1000;

const GET_EVENTS = gql`
    query getEvents($date: String!) {
        gammaEvents: gammaEvents(date: $date) {
            SyncID
            ResName
            Category
            SubCategory
            ResDate
            StartTime
            EndTime
            TotalBooked
            Capacity
            Area
            Description
            Age
            FilmCertification
            InformationPage
            ResID
            lastSync
        }
        corporateEvents: corporateEvents(startDate: $date, endDate: $date) {
            masterBookId
            eventName
            eventAttendees
            eventStart
            eventEnd
            bookingStatus
            resourceName
            bookingCategory
            eventType
            spaces {
                area
            }
            lastSync
        }
        externalEvents: externalEvents(date: $date) {
            ResName
            Category
            SubCategory
            EventID
            Location
            DateTimeStart
            InfoURL
            lastUpdate
        }
    }
`;

const SYNC_EVENTS = gql`
    mutation allEventSync($from: String, $to: String) {
        special: syncEventsFromGamma(
            startDate: $from
            endDate: $to
            type: SPECIAL
        ) {
            ResName
        }
        education: syncEventsFromGamma(
            startDate: $from
            endDate: $to
            type: EDUCATION
        ) {
            ResName
        }
        birthday: syncEventsFromGamma(
            startDate: $from
            endDate: $to
            type: BIRTHDAY
        ) {
            ResName
        }
        planetarium: syncEventsFromGamma(
            startDate: $from
            endDate: $to
            type: PLANETARIUM
        ) {
            ResName
        }
        scienceshow: syncEventsFromGamma(
            startDate: $from
            endDate: $to
            type: SCIENCESHOW
        ) {
            ResName
        }
        workshop: syncEventsFromGamma(
            startDate: $from
            endDate: $to
            type: WORKSHOP
        ) {
            ResName
        }
        tower: syncEventsFromGamma(
            startDate: $from
            endDate: $to
            type: TOWER
        ) {
            ResName
        }
        cineworld: syncEventsFromGamma(
            startDate: $from
            endDate: $to
            type: CINEWORLD
        ) {
            ResName
        }
        corporate: syncCorporateEventsFromGammaDb(
            startDate: $from
            endDate: $to
        ) {
            masterBookId
        }
    }
`;

const GSCEvents = ({date}) => {
    const classes = useStyles();
    const {
        loading: queryLoading,
        error: queryError,
        data,
        refetch,
    } = useQuery(GET_EVENTS, {
        variables: {date: apiFormat(date)},
        notifyOnNetworkStatusChange: true,
        pollInterval: isToday(date) ? TEN_MINUTES : 0,
    });
    const [
        triggerEventsSync,
        {loading: mutationLoading, error: mutationError},
    ] = useMutation(SYNC_EVENTS, {
        update: () => refetch(),
    });

    // Always show confirmed events in the dashboard; allow provisional/cancelled to come from selection
    const corpStatusFilter = {
        ...useSelector(corpStatusFilterSelector),
        confirmed: true,
    };

    if (queryLoading || mutationLoading)
        return (
            <GSCWrappedLoading heading={true} mightBeLong={mutationLoading}>
                <Typography variant="h4" component="h4">
                    Events
                </Typography>
            </GSCWrappedLoading>
        );
    if (queryError || !Array.isArray(data.gammaEvents))
        return (
            <GammaQLDataFetchError
                theThing="Events"
                showHeader
                refetch={refetch}
            />
        );

    if (mutationError)
        return (
            <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
                className={classes.gridContainer}
            >
                <GSCDateHeader date={date} />
                <Typography variant="body1">
                    Could not retrieve a result from Gamma
                </Typography>
            </Grid>
        );

    if (data.gammaEvents.length === 0 && data.externalEvents.length === 0)
        return (
            <Grid
                container
                className={classes.gridContainer}
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
            >
                <Grid item xs={12}>
                    <Typography variant="h4" component="h4">
                        Events
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="flex-start"
                >
                    <Typography variant="body1">
                        No event details are available for this date yet
                    </Typography>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => {
                            triggerEventsSync({
                                variables: {
                                    from: apiFormat(date),
                                    to: apiFormat(date),
                                },
                            });
                        }}
                    >
                        Ask Gamma for Events
                    </Button>
                </Grid>
            </Grid>
        );

    return (
        <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="center"
            className={classes.gridContainer}
        >
            <Grid
                item
                xs={12}
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
            >
                <Typography variant="h4" component="h4">
                    Events
                </Typography>
                <RefetchButton onClick={() => refetch()} offsetPadding={true} />
            </Grid>
            <Grid item xs={12} container className={classes.categoryContainer}>
                <GSCCategoryWrapper
                    events={[
                        ...data.gammaEvents,
                        ...data.corporateEvents
                            .filter(filterByStatusDisplay(corpStatusFilter))
                            .map((event) => ({
                                ...event,
                                Category: "Corporate",
                            })),
                        ...data.externalEvents,
                    ]}
                    refetch={() => refetch()}
                />
            </Grid>
        </Grid>
    );
};

export default GSCEvents;
