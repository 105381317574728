/** @format */

import {
    format,
    subDays,
    addDays,
    subWeeks,
    addWeeks,
    subYears,
    addYears,
} from "date-fns";

export const appVersionString = `Version ${process.env.REACT_APP_VERSION}`; // As defined in .env
export const storeRootString = "gsctoday";

export const apolloParams = {
    uri:
        process.env.NODE_ENV === "development"
            ? "https://gammaql.gsc.org.uk"
            : "https://gammaql.gsc.org.uk",
};

export const apiFormat = (date) => format(date, "yyyy-MM-dd");

export const aadAppId = "3b5cbb50-9c64-44de-bed1-a3001e465849";
export const appRootUri =
    process.env.NODE_ENV === "development"
        ? "http://localhost:3000"
        : "https://today.gsc.org.uk";
export const authRedirectUri = `${appRootUri}/auth/return`;
export const msGraphScopes = ["User.Read", "Group.Read.All"];

export const towerWeatherUrl = "https://towerweather.gsc.org.uk";
export const websiteNodeRoot = "https://www.glasgowsciencecentre.org/node/";
export const bbfcIconUrl = "https://style.gsc.org.uk/icons/bbfc/";
export const bbfcIconFiletype = ".png";

// Maximum date range for Corporate Event List update button to be visible.
// Currently equal to 2 weeks
export const updateRangeMax = 1209540000;

const corpEventConfirmedStatuses = [
    "AREA CLOSED",
    "COR - Booked for GSC Staff",
    "COR - Cineworld Black Week",
    "COR - Confirmed",
    "COR - Contract Issued",
    "COR - Final Balance Paid",
    "COR - Final Invoice Issued",
    "COR - Function Sheet Issued",
];
const corpEventProvisionalStatuses = [
    "COR - Provisional (A)",
    "COR - Provisional (B)",
];
const corpEventCancelledStatuses = ["COR - Cancelled"];
const corpEventHiddenStatuses = [
    "COR - Enquiry",
    "COR - Lost Business",
    "COR - Turned Down",
];

export const getCorpEventStatusCategory = (status) => {
    const normalisedStatus = status.trim().toLowerCase();
    const statusIsIn = (array, status) =>
        array
            .map((confirmed) => confirmed.trim().toLowerCase())
            .includes(status);

    if (statusIsIn(corpEventHiddenStatuses, normalisedStatus)) return "hidden";
    if (statusIsIn(corpEventConfirmedStatuses, normalisedStatus))
        return "confirmed";
    if (statusIsIn(corpEventProvisionalStatuses, normalisedStatus))
        return "provisional";
    if (statusIsIn(corpEventCancelledStatuses, normalisedStatus))
        return "cancelled";
    return null;
};
export const corpEventIsInternal = (eventResource) =>
    String(eventResource).toLowerCase() ===
    ".Corporate Internal Events".toLowerCase();

export const notesExcludedFromExternalEvents = ["billing"];

export const categories = {
    Public: {
        displayName: "Public",
        subcategories: [
            "Birthday Parties",
            "Planetarium",
            "Science Shows",
            "Workshops",
        ],
        colourMapping: "blue",
    },
    Education: {
        displayName: "Education",
        subcategories: ["Activities", "Lunch", "Shop", "Coaches"],
        colourMapping: "teal",
    },
    Special: {
        displayName: "Special Events",
        subcategories: false,
        colourMapping: "pink",
    },
    Corporate: {
        displayName: "Corporate Events",
        subcategories: false,
        colourMapping: "purple",
        nonGamma: true,
    },
    Tower: {
        displayName: "Tower",
        subcategories: false,
        colourMapping: "orange",
    },
    Cineworld: {
        displayName: "IMAX",
        subcategories: false,
        colourMapping: "gold",
    },
    External: {
        displayName: "External",
        subcategories: ["SEC Events", "Ibrox"],
        colourMapping: "darkteal",
        nonGamma: true,
    },
};

export const maxBarChartVisitors = 2000;
export const numberOfBarChartDays = 7;

export const datePickerPastStart = new Date("1 April 2014");
export const datePickerFutureEnd = addYears(new Date(), 2);

export const autoExpandBreakpoint = 767; //px
export const navDrawerWidth = 250; //px

export const comparisonPaneConfigs = {
    week: {
        timeUnitName: "Day",
        canonicalRouteRoot: "/numbers/week",
        fixedTimeframe: false,
        timeUnitsEitherSide: 3,
        subTimeUnit: (date) => subDays(date, 1),
        addTimeUnit: (date) => addDays(date, 1),
    },
    month: {
        timeUnitName: "Week",
        canonicalRouteRoot: "/numbers/month",
        fixedTimeframe: false,
        timeUnitsEitherSide: 2,
        subTimeUnit: (date) => subWeeks(date, 1),
        addTimeUnit: (date) => addWeeks(date, 1),
    },
    year: {
        timeUnitName: "Year",
        canonicalRouteRoot: "/numbers/year",
        fixedTimeframe: true,
        subTimeUnit: (date) => subYears(date, 1),
        addTimeUnit: (date) => addYears(date, 1),
    },
};
