/** @format */

import {
    subDays,
    addDays,
    subWeeks,
    addWeeks,
    addYears,
    subYears,
    startOfWeek,
    endOfWeek,
    startOfMonth,
    endOfMonth,
    getISOWeek,
    getMonth,
    setMonth,
} from "date-fns";
import dateify from "../../utils/dateify";

export const groupNumbers = (type, numbers) => {
    switch (type) {
        case "Year":
            const numbersWithMonthLabels = numbers.map((day) => {
                const date = dateify(day.date);
                return {
                    total: day.total,
                    date,
                    monthLabel: getMonth(date),
                };
            });
            const uniqueMonthLabels = Array.from(
                new Set(numbersWithMonthLabels.map((day) => day.monthLabel))
            );
            return uniqueMonthLabels.map((month) => {
                const thisMonthData = numbersWithMonthLabels.filter(
                    (day) => day.monthLabel === month
                );
                const startOfThisMonth = thisMonthData[0].date;
                const monthTotal = thisMonthData.reduce(
                    (accumulator, day) => accumulator + Number(day.total),
                    0
                );
                return {total: monthTotal, date: startOfThisMonth};
            });
        case "Week":
            const numbersWithWeekLabels = numbers.map((day) => {
                const date = dateify(day.date);
                return {
                    total: day.total,
                    date,
                    weekLabel: getISOWeek(date),
                };
            });
            const uniqueWeekLabels = Array.from(
                new Set(numbersWithWeekLabels.map((day) => day.weekLabel))
            );
            return uniqueWeekLabels.map((week) => {
                const thisWeekData = numbersWithWeekLabels.filter(
                    (day) => day.weekLabel === week
                );
                const startOfThisWeek = thisWeekData[0].date;
                const weekTotal = thisWeekData.reduce(
                    (accumulator, day) => accumulator + Number(day.total),
                    0
                );
                return {total: weekTotal, date: startOfThisWeek};
            });
        case "Day":
        default:
            return numbers.map((day) => ({
                total: day.total,
                date: dateify(day.date),
            }));
    }
};

const getPrevious1April = (date) => {
    const APRIL = 3;
    const intermediary = getMonth(date) < APRIL ? subYears(date, 1) : date;
    return startOfMonth(setMonth(intermediary, APRIL));
};

const getNext31March = (date) => {
    const MARCH = 2;
    const intermediary = getMonth(date) > MARCH ? addYears(date, 1) : date;
    return endOfMonth(setMonth(intermediary, MARCH));
};

export const getStartDate = (date, type, fixedTimeframe, timeUnitsEitherSide) =>
    fixedTimeframe
        ? getPrevious1April(date)
        : type === "Week"
        ? startOfWeek(subWeeks(date, timeUnitsEitherSide), {weekStartsOn: 1})
        : subDays(date, timeUnitsEitherSide);

export const getEndDate = (date, type, fixedTimeframe, timeUnitsEitherSide) =>
    fixedTimeframe
        ? getNext31March(date)
        : type === "Week"
        ? endOfWeek(addWeeks(date, timeUnitsEitherSide), {weekStartsOn: 1})
        : addDays(date, timeUnitsEitherSide);
