/** @format */

import React from "react";
import RouteMapper from "./RouteMapper";
import CorporateList from "../components/root/CorporateList";
import CorporateEvent from "../components/corporateEvent/";
import PrintCorporateEvents from "../components/corporateEvent/print/";

const routes = [
    {
        path: "/event/:eventId",
        render: ({match}) => (
            <CorporateEvent bookingReference={match.params.eventId} />
        ),
    },
    {
        path: "/print/:eventList",
        render: ({match}) => (
            <PrintCorporateEvents events={match.params.eventList} />
        ),
    },
    {
        path: "/list/:startDate/:endDate",
        render: ({match}) => (
            <CorporateList
                forceStart={match.params.startDate}
                forceEnd={match.params.endDate}
            />
        ),
    },
    {
        path: "/list",
        render: ({match}) => <CorporateList />,
    },
];

const CorporateRoute = ({match}) => (
    <RouteMapper routes={routes} rootPath={match.path} defaultRoute="/list" />
);

export default CorporateRoute;
