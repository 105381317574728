/** @format */

import React from "react";
import {Text, View, StyleSheet} from "@react-pdf/renderer";
import {getNonEmptyNotes, capitalFirst} from "../../../utils/corpEventFilters";
import {notesExcludedFromExternalEvents} from "../../../config";

const styles = StyleSheet.create({
    section: {
        margin: "2 6",
        padding: 5,
    },
    columnContainer: {
        margin: 5,
        flexDirection: "column",
        justifyContent: "space-between",
    },
    heading: {
        fontFamily: "ProximaNova",
        color: "#2e2d44",
        margin: "3 0",
    },
    h1size: {
        fontSize: "24pt",
    },
    h2size: {
        fontSize: "20pt",
    },
    h3size: {
        fontSize: "16pt",
    },
    bodyText: {
        margin: 1,
        fontFamily: "Arial",
        fontSize: "10pt",
    },
    bold: {
        fontWeight: "bold",
    },
});

const SingleGeneralNoteType = ({type, note}) =>
    type !== "timetable" && (
        /* Line breaks in the note need to be just \n and not \r\n, otherwise \r is rendered as a "missing character" square */
        <View style={styles.columnContainer}>
            <Text style={[styles.heading, styles.h3size]} minPresenceAhead={30}>
                {capitalFirst(type)}
            </Text>
            <Text style={styles.bodyText}>{note.replace(/\r\n/gi, "\n")}</Text>
        </View>
    );

const GeneralNotes = ({notes, internal, includedNoteTypes}) => {
    const generalNotes = getNonEmptyNotes(notes).filter(
        ([key, value]) => includedNoteTypes[key]
    );
    const displayNotes = internal
        ? generalNotes
        : generalNotes.filter(
              ([key, value]) => !notesExcludedFromExternalEvents.includes(key)
          );
    return (
        Boolean(displayNotes.length) && (
            <View style={styles.section}>
                <Text
                    style={[styles.heading, styles.h2size]}
                    minPresenceAhead={50}
                >
                    Departmental Notes
                </Text>
                {displayNotes.map(([noteType, noteValue]) => (
                    <SingleGeneralNoteType
                        key={noteType}
                        type={noteType}
                        note={noteValue}
                    />
                ))}
            </View>
        )
    );
};

export default GeneralNotes;
