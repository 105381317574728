/** @format */

import React from "react";
import {Redirect} from "react-router-dom";
import {Grid, Typography} from "@material-ui/core";
import {GSCAuthConsumer, isLoggedIn, clearGSCAuth, initialAuthValues} from ".";
import GSCWrappedLoading from "../general/WrappedLoading";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        margin: theme.spacing(1, 0),
        padding: theme.spacing(1),
        [theme.breakpoints.up("md")]: {
            padding: theme.spacing(2, 4),
        },
    },
}));

const AuthLogout = ({doLogout}) => {
    const classes = useStyles();
    return (
        <GSCAuthConsumer>
            {({setAuth}) => {
                if (!isLoggedIn()) return <Redirect to="/auth/details" />;
                if (doLogout) {
                    console.log(
                        `[${new Date().toLocaleTimeString()}]: Initiating logout`
                    );
                    setAuth({...initialAuthValues, pics: null});
                    clearGSCAuth();
                }
                return (
                    <Grid
                        container
                        justify="center"
                        item
                        xs={12}
                        className={classes.gridContainer}
                    >
                        <Typography variant="h4" component="h2">
                            Logging Out
                        </Typography>
                        <GSCWrappedLoading />
                    </Grid>
                );
            }}
        </GSCAuthConsumer>
    );
};

export default AuthLogout;
