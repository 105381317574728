/** @format */

import React from "react";
import {
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    Collapse,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    reverseText: {
        display: "flex",
        flexDirection: "column-reverse",
    },
    avatar: {
        color: theme.gsc.white,
        backgroundColor: theme.gsc.darkteal,
    },
}));

const DetailItem = ({label, value}) => {
    const classes = useStyles();
    return (
        <ListItem>
            <ListItemAvatar>
                <Avatar className={classes.avatar}>
                    {label
                        .split(" ")
                        .map((word) => word.charAt(0).toUpperCase())
                        .join("")}
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                classes={{root: classes.reverseText}}
                primary={value}
                secondary={label}
            />
        </ListItem>
    );
};

const GSCNumbersDetails = ({stats, timetravel, expanded}) => {
    const truthyStats = stats.filter((stat) => Boolean(stat.value));
    if (
        timetravel === "future" ||
        !Array.isArray(truthyStats) ||
        !truthyStats.length
    )
        return null;
    return (
        <Collapse in={expanded}>
            <Grid item xs={12} container justify="center">
                <List dense>
                    {stats.map((stat) => (
                        <DetailItem
                            key={stat.label}
                            label={stat.label}
                            value={stat.value}
                        />
                    ))}
                </List>
            </Grid>
        </Collapse>
    );
};

export default GSCNumbersDetails;
