/** @format */

import React from "react";
import RouteMapper from "./RouteMapper";
import EventsList from "../components/root/EventsList";

const routes = [
    {
        path: "/:eventId",
        render: ({match}) => <EventsList />,
    },
    {
        path: "/list",
        render: () => <EventsList />,
    },
];

const EventsRoute = ({match}) => (
    <RouteMapper routes={routes} rootPath={match.path} defaultRoute="/list" />
);

export default EventsRoute;
