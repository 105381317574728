/** @format */

import React from "react";
import {Text, Image, View, StyleSheet} from "@react-pdf/renderer";
import {format} from "date-fns";
import {getCorpEventType} from "../../../utils/corpEventFilters";

const styles = StyleSheet.create({
    logo: {
        width: 150,
        height: 80,
    },
    section: {
        marginTop: 2,
        marginBottom: 1,
        marginLeft: 6,
        marginRight: 6,
        paddingTop: 2,
        paddingBottom: 1,
        paddingLeft: 5,
        paddingRight: 5,
    },
    rowContainer: {
        margin: "1 5",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    columnContainer: {
        margin: "1 5",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    heading: {
        fontFamily: "ProximaNova",
        color: "#2e2d44",
        margin: "3 0",
        fontSize: "16pt",
    },
    textListItem: {
        margin: 1,
        fontFamily: "Arial",
        fontSize: "10pt",
    },
    bold: {
        fontWeight: "bold",
    },
    textBlock: {
        padding: 20,
    },
});

const TextListItem = ({title, body}) => (
    <Text style={styles.textListItem}>
        <Text style={[styles.textListItem, styles.bold]}>{title}</Text>: {body}
    </Text>
);

const PageHeader = ({event}) => (
    <View style={[styles.section, styles.pageHeader]} fixed>
        <View style={styles.rowContainer}>
            <Image src="/img/gscLogo.png" style={styles.logo} />
            <View style={[styles.columnContainer, styles.textBlock]}>
                <TextListItem title="Ref" body={event.masterBookId} />
                <Text style={styles.heading}>{event.eventName}</Text>
                <TextListItem
                    title="Event Start"
                    body={format(
                        Number(event.eventStart),
                        "EEEE do MMMM yyyy, HH:mm"
                    )}
                />
                <TextListItem
                    title="Event End"
                    body={format(
                        Number(event.eventEnd),
                        "EEEE do MMMM yyyy, HH:mm"
                    )}
                />
                <TextListItem
                    title="Total Attendees"
                    body={event.eventAttendees}
                />
                <TextListItem
                    title="Main Contact On Day"
                    body={event.mainContactOnDay}
                />
                <TextListItem
                    title="Event Type"
                    body={getCorpEventType(event)}
                />
                <TextListItem title="Event Status" body={event.bookingStatus} />
                <TextListItem title="Event Manager" body={event.eventManager} />
                <TextListItem title="Ops Manager" body={event.opsManager} />
            </View>
        </View>
    </View>
);

export default PageHeader;
