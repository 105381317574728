/** @format */

import {startOfMonth, endOfMonth} from "date-fns";

export const corpDateRangeSelector = (state) => {
    return {start: state.corpDateRange.start, end: state.corpDateRange.end};
};

export const corpDateRangeActions = {
    changeStart: "corp/changeRangeStart",
    changeEnd: "corp/changeRangeEnd",
};

const corpDateRangeReducer = (
    state = {
        start: startOfMonth(new Date()).getTime(),
        end: endOfMonth(new Date()).getTime(),
    },
    action
) => {
    switch (action.type) {
        case corpDateRangeActions.changeStart: {
            return {...state, start: action.payload.getTime()};
        }
        case corpDateRangeActions.changeEnd: {
            return {...state, end: action.payload.getTime()};
        }
        default:
            return state;
    }
};

export default corpDateRangeReducer;
