/** @format */

import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import NavigationDrawer from "./NavDrawer";
import MenuIcon from "@material-ui/icons/Menu";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";
import {push} from "connected-react-router";
import UserPic from "../auth/UserPic";
import {makeStyles} from "@material-ui/core/styles";
import {navDrawerWidth} from "../../config";

const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    title: {
        flexGrow: 1,
    },
    logo: {
        width: "70px",
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(1),
        [theme.breakpoints.up("md")]: {
            width: "80px",
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
        },
    },
    appLink: {
        "textDecoration": "none",
        "color": theme.gsc.offwhite,
        "&:hover": {
            textDecoration: "none",
            color: theme.gsc.offwhite,
        },
    },
}));

const GSCNavBar = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [navDrawerOpen, setNavDrawerOpen] = React.useState(false);
    const handleDrawerToggle = () => setNavDrawerOpen(!navDrawerOpen);

    return (
        <>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open navigation menu"
                        onClick={handleDrawerToggle}
                    >
                        {navDrawerOpen ? <MenuOpenIcon /> : <MenuIcon />}
                    </IconButton>
                    <Link to="/" className={classes.appLink}>
                        <img
                            className={classes.logo}
                            src="/img/gsclogo-inverse.png"
                            alt=""
                        />
                    </Link>
                    <Link
                        to="/"
                        className={`${classes.appLink} ${classes.title}`}
                    >
                        <Typography variant="h4" component="h1">
                            GSC Today
                        </Typography>
                    </Link>
                    <IconButton
                        edge="end"
                        color="inherit"
                        aria-label="view login details"
                        onClick={() => dispatch(push("/auth"))}
                    >
                        <UserPic size={48} displaySize={32} />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <NavigationDrawer
                drawerWidth={navDrawerWidth}
                open={navDrawerOpen}
                toggle={handleDrawerToggle}
            />
        </>
    );
};

export default GSCNavBar;
