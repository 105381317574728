/** @format */

import React from "react";
import {Grid, Typography} from "@material-ui/core";
import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import GSCWrappedLoading from "../general/WrappedLoading";
import GSCDateHeader from "../general/DateHeader";
import GSCWeatherCard from "./WeatherCard";
import {isToday, format, setHours, setMinutes} from "date-fns";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    gridItem: {
        textAlign: "center",
        [theme.breakpoints.up("md")]: {
            textAlign: "left",
        },
    },
}));

const THIRTY_MINUTES = 30 * 60 * 1000;

const GET_DAY_PARAMS = gql`
    query dayParameters($date: String!) {
        dayParameters(date: $date) {
            date
            day_type
            open_status
            open_hours
            weather {
                day {
                    maxTempFeelsLike
                    weatherTypeDescription
                    weatherTypeIcon
                    windSpeed
                    windDirection
                    windGustNoon
                    precipitationProbability
                }
                night {
                    minTempFeelsLike
                }
            }
        }
    }
`;

const reformatHours = (hours) => {
    try {
        if (!hours || hours.toLowerCase() === "closed") return hours;
        // hours comes from GammaQL as e.g. "10.00 - 17.00"
        // This reformats as "10:00–17:00"
        const splitHours = String(hours).split("-", 2);
        const fromTime = splitHours[0].trim().split(".", 2);
        const toTime = splitHours[1].trim().split(".", 2);
        const from = format(
            setMinutes(setHours(new Date(), fromTime[0]), fromTime[1]),
            "HH:mm"
        );
        const to = format(
            setMinutes(setHours(new Date(), toTime[0]), toTime[1]),
            "HH:mm"
        );
        return `${from}–${to}`; //That's an en dash!
    } catch (e) {
        console.warn(e);
        return hours;
    }
};

const GSCDayParams = ({date}) => {
    const classes = useStyles();
    const todayIsSelected = isToday(date);

    const {loading, error, data, refetch} = useQuery(GET_DAY_PARAMS, {
        variables: {date},
        notifyOnNetworkStatusChange: true,
        pollInterval: todayIsSelected ? THIRTY_MINUTES : 0,
        fetchPolicy: "cache-and-network",
    });

    if (loading) return <GSCWrappedLoading date={date} heading={true} />;
    const params = data
        ? data.dayParameters
        : {day_type: null, open_status: null, weather: null};
    if (
        error ||
        (params.day_type === null &&
            params.open_status === null &&
            params.weather === null)
    )
        return (
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                className={classes.gridContainer}
            >
                <Grid item xs={12} md={6} lg={7} className={classes.gridItem}>
                    <GSCDateHeader date={date} rootPath="/date" />
                </Grid>
            </Grid>
        );

const openString =
  params.open_status &&
  (params.open_status?.toLowerCase() === "closed" ||
    params.open_hours?.toLowerCase() === "closed"
      ? "closed"
      : `${params.open_status} ${reformatHours(params.open_hours)}`);
const gscIs = todayIsSelected ? `GSC is` : `GSC was`;
const onThisDate = todayIsSelected ? `today` : `on this date`;


    return (
        <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            className={classes.gridContainer}
        >
            <Grid item xs={12} md={6} lg={7} className={classes.gridItem}>
                <GSCDateHeader date={date} rootPath="/date" />
                {params.open_status && (
                    <Typography variant="body1">
                        {gscIs} <strong>{openString}</strong> {onThisDate}
                    </Typography>
                )}
            </Grid>
            <Grid item xs={12} md={5} lg={4} className={classes.gridItem}>
                <GSCWeatherCard
                    weather={params.weather}
                    refetch={() => refetch()}
                />
            </Grid>
        </Grid>
    );
};

export default GSCDayParams;
