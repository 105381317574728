/** @format */

import React from "react";
import {
    Grid,
    Card,
    CardContent,
    List,
    Typography,
    IconButton,
} from "@material-ui/core";
import NotesTabs from "./NotesTabs";
import EventListItem from "./EventListItem";
import StartIcon from "@material-ui/icons/Schedule";
import EndIcon from "@material-ui/icons/WatchLater";
import PeopleIcon from "@material-ui/icons/People";
import PurposeIcon from "@material-ui/icons/TrackChanges";
import BookingIcon from "@material-ui/icons/Bookmark";
import LocationIcon from "@material-ui/icons/Room";
import SeatIcon from "@material-ui/icons/EventSeat";
import CollapseIcon from "@material-ui/icons/ExpandLess";
import ExpandIcon from "@material-ui/icons/ExpandMore";
import {format, isSameDay} from "date-fns";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    card: {
        margin: theme.spacing(2, 0),
        [theme.breakpoints.up("sm")]: {
            margin: theme.spacing(2, 1),
        },
        border: `1px solid ${theme.gsc.purple}`,
    },
    cardContent: {
        padding: theme.spacing(1, 2),
        backgroundColor: theme.gsc.white,
    },
}));

const getEventSpan = (spaceStart, spaceEnd) => {
    const start = Number(spaceStart);
    const end = Number(spaceEnd);
    const sameDay = isSameDay(start, end);
    const endFormat = sameDay ? "HH:mm" : "do MMM, HH:mm";
    const separator = sameDay ? "" : " ";
    return `${format(start, "do MMM, HH:mm")}${separator}–${separator}${format(
        end,
        endFormat
    )}`;
};

const EventSpaceList = ({space}) => (
    <List dense>
        <EventListItem
            primary={space.spaceType}
            secondary="Purpose"
            icon={<PurposeIcon />}
        />
        <EventListItem
            primary={format(
                Number(space.spaceStart),
                "EEEE do MMMM yyyy, HH:mm"
            )}
            secondary="Start Time"
            icon={<StartIcon />}
        />
        <EventListItem
            primary={format(Number(space.spaceEnd), "EEEE do MMMM yyyy, HH:mm")}
            secondary="End Time"
            icon={<EndIcon />}
        />
        <EventListItem
            primary={space.spaceAttendees}
            secondary="Attendees"
            icon={<PeopleIcon />}
        />
        <EventListItem
            primary={space.area}
            secondary="Area"
            icon={<LocationIcon />}
        />
        <EventListItem
            primary={space.roomStyle}
            secondary="Room Style"
            icon={<SeatIcon />}
        />
        <EventListItem
            primary={space.linkedBookId}
            secondary="Space Reference"
            icon={<BookingIcon />}
        />
    </List>
);

const EventSpaceCard = ({space, internal}) => {
    const classes = useStyles();
    const [selected, setSelected] = React.useState(false);
    return (
        <Grid item xs={12} md={6} lg={4}>
            <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                    <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                    >
                        <Grid
                            item
                            xs={10}
                            container
                            direction="column"
                            justify="center"
                            alignItems="flex-start"
                        >
                            <Typography variant="h5">
                                {space.spaceName || space.area}
                            </Typography>
                            {selected !== 0 && (
                                <Typography variant="body2">
                                    {getEventSpan(
                                        space.spaceStart,
                                        space.spaceEnd
                                    )}
                                    &ensp;|&ensp;
                                    {space.spaceAttendees} attendee
                                    {space.spaceAttendees === 1 ? "" : "s"}
                                    &ensp;|&ensp;
                                    {space.area}
                                </Typography>
                            )}
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            container
                            direction="column"
                            justify="center"
                            alignItems="flex-end"
                        >
                            {selected === false ? (
                                <IconButton onClick={() => setSelected(0)}>
                                    <ExpandIcon />
                                </IconButton>
                            ) : (
                                <IconButton onClick={() => setSelected(false)}>
                                    <CollapseIcon />
                                </IconButton>
                            )}
                        </Grid>
                    </Grid>
                </CardContent>
                <NotesTabs
                    notes={space.spaceNotes}
                    startClosed
                    allowClosure
                    FirstTab={EventSpaceList}
                    firstTabProps={{space, label: "Details"}}
                    controlled={[selected, setSelected]}
                    internal={internal}
                />
            </Card>
        </Grid>
    );
};

export default EventSpaceCard;
