/** @format */

import {LOCATION_CHANGE} from "connected-react-router";
import {startOfDay, addDays, subDays} from "date-fns";

export const getDateFromPath = (path) => {
    try {
        const {
            groups: {dateString},
        } = /^\/(?:[^/\d]+\/)+(?<dateString>.*)/.exec(path);
        switch (dateString) {
            case "today":
                return startOfDay(new Date());
            case "yesterday":
                return startOfDay(subDays(new Date(), 1));
            case "tomorrow":
                return startOfDay(addDays(new Date(), 1));
            default:
                const {
                    groups: {year, monthOneIndexed, day},
                } = /(?<year>[\d]{4})[./-](?<monthOneIndexed>[\d]{2})[./-](?<day>[\d]{2})/.exec(
                    dateString
                );
                return startOfDay(new Date(year, monthOneIndexed - 1, day));
        }
    } catch (e) {
        return null;
    }
};

export const dateSelector = (state) => {
    if (!state.selectedDate) return null;
    return new Date(state.selectedDate);
};

export const selectedDateActions = {
    forcedUpdate: "selectedDate/forcedUpdate",
};

const selectedDateReducer = (state = new Date().getTime(), action) => {
    switch (action.type) {
        case LOCATION_CHANGE: {
            const dateFromPath = getDateFromPath(
                action.payload.location.pathname
            );
            if (!dateFromPath) return null;
            return dateFromPath.getTime();
        }
        case selectedDateActions.forcedUpdate: {
            return action.payload.date.getTime();
        }
        default:
            return state;
    }
};

export default selectedDateReducer;
