/** @format */

const convertBlobToBase64 = (blob) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.readAsDataURL(blob);
    });

const getImage = (size) => async (token) => {
    // Valid sizes are 48, 64, 96, 120, 240, 360, 432, 504, 648,
    // or null to get the largest available
    try {
        const requestUrl = size
            ? `https://graph.microsoft.com/v1.0/me/photos/${size}x${size}/$value`
            : "https://graph.microsoft.com/v1.0/me/photos/$value";
        const response = await fetch(requestUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const blob = await response.blob();
        const base64image = await convertBlobToBase64(blob);
        return base64image;
    } catch (err) {
        console.error(err);
        return null;
    }
};

const getUserImages = async (auth) => {
    const pic48 = await getImage(48)(auth.accessToken);
    const pic120 = await getImage(120)(auth.accessToken);
    const images = {
        48: pic48,
        120: pic120,
    };
    return images;
};

export default getUserImages;
