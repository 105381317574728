/** @format */

import React from "react";
import {
    Grid,
    Paper,
    Typography,
    IconButton,
    List,
    Button,
} from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import LocationIcon from "@material-ui/icons/Room";
import EventDetailsList from "./EventDetailsList";
import NotesTabs from "./NotesTabs";
import SpaceCard from "./EventSpaceCard";
import EventListItem from "./EventListItem";
import LastUpdatedTooltip from "../general/LastUpdatedTooltip";
import RefetchButton from "../general/RefetchButton";
import {useDispatch} from "react-redux";
import {push} from "connected-react-router";
import {
    getEventSpacesList,
    getEventSpacesByDate,
} from "../../utils/corpEventFilters";
import {corpEventIsInternal} from "../../config";
import {format} from "date-fns";
import {makeStyles} from "@material-ui/core/styles";
import gql from "graphql-tag";
import {useMutation} from "@apollo/react-hooks";
import {apiFormat} from "../../config";

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        padding: theme.spacing(1, 0),
        [theme.breakpoints.up("md")]: {
            padding: theme.spacing(1, 3),
        },
    },
    eventTitle: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    detailsPaper: {
        [theme.breakpoints.up("md")]: {
            marginRight: theme.spacing(2),
        },
        padding: theme.spacing(1, 2),
        border: `1px solid ${theme.gsc.purple}`,
    },
    topBlock: {
        marginBottom: theme.spacing(1),
    },
    spacesContainer: {
        width: "100%",
        margin: theme.spacing(3, 0),
    },
    spaceDateContainer: {
        margin: theme.spacing(2, 0),
    },
}));

const SpacesNote = ({spaces}) => (
    <List dense>
        {spaces.map((space, idx) => (
            <EventListItem key={idx} primary={space} icon={<LocationIcon />} />
        ))}
    </List>
);

const SingleEvent = ({event, refetch}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const spaces = getEventSpacesList(event);
    const {eventDates, eventSpacesByDate} = getEventSpacesByDate(event);

    const eventIsInternal = corpEventIsInternal(event.resourceName);
    const startDateForUpdate = apiFormat(new Date(Number(event.eventStart)));
    const endDateForUpdate = apiFormat(new Date(Number(event.eventEnd)));

    const SYNC_EVENTS = gql`
        mutation ($startDate: String, $endDate: String) {
            syncCorporateEventsFromGammaDb(
                startDate: $startDate
                endDate: $endDate
            ) {
                masterBookId
                lastSync
                eventName
                bookingStatus
                spaces {
                    linkedBookId
                    spaceName
                    area
                }
            }
        }
    `;
    const [triggerEventsSync, {error: mutationError}] = useMutation(
        SYNC_EVENTS,
        {
            update: () => refetch(),
        }
    );

    const corpEventUpdate = () => {
        triggerEventsSync({
            variables: {
                startDate: startDateForUpdate,
                endDate: endDateForUpdate,
            },
        });

        if (mutationError) {
            console.error("Error updating:", mutationError);
        }
    };

    return (
        <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="center"
            className={classes.gridContainer}
        >
            <Grid
                item
                xs={12}
                className={classes.eventTitle}
                container
                direction="row"
                justify="space-between"
            >
                <Grid item xs={12} sm={8}>
                    <Typography variant="h4" component="h2">
                        {event.eventName}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={4}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                >
                    <IconButton
                        onClick={() =>
                            dispatch(
                                push(`/corporate/print/${event.masterBookId}`)
                            )
                        }
                    >
                        <PrintIcon />
                    </IconButton>
                    <RefetchButton onClick={() => refetch()} />
                </Grid>
            </Grid>
            <Grid
                item
                xs={12}
                container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
            >
                <Grid item xs={12} md={6} className={classes.topBlock}>
                    <Paper className={classes.detailsPaper}>
                        <EventDetailsList event={event} />
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6} className={classes.topBlock}>
                    <NotesTabs
                        notes={event.eventNotes}
                        FirstTab={SpacesNote}
                        firstTabProps={{spaces, label: "Spaces in Use"}}
                        border="purple"
                        internal={eventIsInternal}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} className={classes.spacesContainer}>
                <Typography variant="h5" component="h3">
                    Event Spaces
                </Typography>
                {eventDates.map(
                    (eventDate, idx) =>
                        Boolean(eventSpacesByDate[idx].length) && (
                            <Grid
                                key={eventDate}
                                container
                                direction="column"
                                justify="flex-start"
                                alignItems="flex-start"
                                className={classes.spaceDateContainer}
                            >
                                <Typography variant="h6" component="h4">
                                    {format(eventDate, "EEEE do MMMM")}
                                </Typography>
                                <Grid
                                    item
                                    xs={12}
                                    container
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="flex-start"
                                >
                                    {eventSpacesByDate[idx].map((space) => (
                                        <SpaceCard
                                            space={space}
                                            key={space.linkedBookId}
                                            internal={eventIsInternal}
                                        />
                                    ))}
                                </Grid>
                            </Grid>
                        )
                )}
            </Grid>
            <Grid
                item
                xs={12}
                container
                direction="row"
                justify="center"
                alignItems="center"
            >
                <LastUpdatedTooltip
                    lastSync={event.lastSync}
                    introText="This event was last updated from Gamma"
                />
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => corpEventUpdate()}
                    className={classes.dateButton}
                    style={{margin: "10px"}}
                >
                    Update
                </Button>
            </Grid>
        </Grid>
    );
};

export default SingleEvent;
