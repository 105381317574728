/** @format */

import React, {useState} from "react";
import {IconButton} from "@material-ui/core";
import EventIcon from "@material-ui/icons/Event";
import {DatePicker} from "@material-ui/pickers";
import {useSelector, useDispatch} from "react-redux";
import {push} from "connected-react-router";
import {dateSelector} from "../../redux/selectedDateSlice";
import {getCanonicalRoute} from "../../utils/getCanonicalRoute";
import {datePickerPastStart, datePickerFutureEnd} from "../../config";

const GSCDatePicker = ({rootPath = "/date"} = {}) => {
    const dispatch = useDispatch();
    const selectedDate = useSelector(dateSelector);
    const [open, setOpen] = useState(false);
    return (
        <DatePicker
            onChange={(date) => {
                dispatch(push(getCanonicalRoute(rootPath, date)));
                setOpen(false);
            }}
            value={selectedDate}
            autoOk={false}
            maxDate={datePickerFutureEnd}
            minDate={datePickerPastStart}
            onClose={() => setOpen(false)}
            open={open}
            TextFieldComponent={() => (
                <IconButton color="secondary" onClick={() => setOpen(true)}>
                    <EventIcon />
                </IconButton>
            )}
            variant="dialog"
            showTodayButton={true}
        />
    );
};

export default GSCDatePicker;
