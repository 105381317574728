/** @format */

import React from "react";
import {LinearProgress} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {darken} from "@material-ui/core/styles/colorManipulator";

const useStyles = makeStyles((theme) => ({
    progressRoot: {
        height: 16,
        borderRadius: theme.spacing(0.5),
        backgroundColor: theme.gsc.offwhite,
        border: `1px solid ${darken(theme.gsc.offwhite, 0.1)}`,
    },
    progressBar: {
        backgroundColor: theme.gsc.teal,
    },
}));

const ProgressBar = ({total, max}) => {
    const classes = useStyles();
    return (
        <LinearProgress
            variant="determinate"
            color="secondary"
            value={total ? 100 * (total / max) : 0}
            classes={{root: classes.progressRoot, bar: classes.progressBar}}
        />
    );
};

export default ProgressBar;
