/** @format */

import React from "react";
import {Grid, Typography, Button} from "@material-ui/core";
import {useQuery, useMutation} from "@apollo/react-hooks";
import gql from "graphql-tag";
import GSCWrappedLoading from "../general/WrappedLoading";
import GammaQLDataFetchError from "../general/DataFetchError";
import GSCVisitorNumbersCard from "./VisitorNumbersCard";
import GSCVisitorChartCard from "../visitorchart";
import {isToday, isFuture, subDays} from "date-fns";
import {makeStyles} from "@material-ui/core/styles";
import sortPastToFutureOnISODates from "../../utils/sortPastToFutureOnISODates";
import dateify from "../../utils/dateify";
import {
    apiFormat,
    numberOfBarChartDays,
    maxBarChartVisitors,
} from "../../config";

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        margin: theme.spacing(2, 0),
    },
    gridItem: {
        padding: theme.spacing(0, 3),
    },
}));

const TEN_MINUTES = 10 * 60 * 1000;

const GET_NUMBERS = gql`
    query visitorNumbers($from: String!, $to: String!) {
        visitorNumbers(fromDate: $from, toDate: $to) {
            date
            total
            adults
            children
            sales
            education
            community
            prebooked_phone
            prebooked_online
            members {
                adult
                child
                concession
                student
                carer
                additionalAdult
                additionalChild
                additionalConcession
                additionalStudent
                community
            }
            lastSync
            lastMemberSync
        }
    }
`;

const SYNC_NUMBERS = (date) => {
    if (isFuture(date))
        return gql`
            mutation futureNumbersSync($from: String!, $to: String!) {
                syncPrebookersFromGamma(startDate: $from, endDate: $to) {
                    date
                }
            }
        `;
    return gql`
        mutation allNumbersSync($from: String!, $to: String!) {
            syncPrebookersFromGamma(startDate: $from, endDate: $to) {
                date
            }
            syncNumbersFromGamma(startDate: $from, endDate: $to) {
                date
            }
        }
    `;
};

const GSCNumbers = ({date}) => {
    const classes = useStyles();
    const todayIsSelected = isToday(date);
    const from = todayIsSelected
        ? apiFormat(subDays(date, numberOfBarChartDays - 1))
        : apiFormat(date);
    const to = apiFormat(date);

    const {
        loading: queryLoading,
        error: queryError,
        data,
        refetch,
    } = useQuery(GET_NUMBERS, {
        variables: {from, to},
        notifyOnNetworkStatusChange: true,
        pollInterval: todayIsSelected ? TEN_MINUTES : 0,
    });
    const [
        triggerNumbersSync,
        {loading: mutationLoading, error: mutationError},
    ] = useMutation(SYNC_NUMBERS(date), {
        update: () => refetch(),
    });

    if (queryLoading || mutationLoading)
        return <GSCWrappedLoading date={date} />;
    if (queryError || !Array.isArray(data.visitorNumbers))
        return (
            <GammaQLDataFetchError theThing="Visitor stats" refetch={refetch} />
        );
    if (mutationError)
        return (
            <Grid container className={classes.gridContainer}>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        Could not retrieve a result from Gamma
                    </Typography>
                </Grid>
            </Grid>
        );

    if (
        data.visitorNumbers &&
        data.visitorNumbers.length !== 1 &&
        data.visitorNumbers.length !== 7
    )
        return (
            <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
                className={classes.gridContainer}
            >
                <Grid item xs={12}>
                    <Typography variant="body1">
                        No visitor stats are available for this date yet
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        color="secondary"
                        variant="outlined"
                        onClick={() => {
                            triggerNumbersSync({
                                variables: {from, to},
                            });
                        }}
                    >
                        Ask Gamma for Visitor Stats
                    </Button>
                </Grid>
            </Grid>
        );

    const {visitorNumbers} = data;
    const sortedNumbers = visitorNumbers
        .slice()
        .sort(sortPastToFutureOnISODates);
    const singleDayNumbers = sortedNumbers[sortedNumbers.length - 1];
    const totalPrebooked =
        singleDayNumbers.education +
        singleDayNumbers.community +
        singleDayNumbers.prebooked_phone +
        singleDayNumbers.prebooked_online;
    const dayTotal = singleDayNumbers.total
        ? singleDayNumbers.total
        : totalPrebooked;
    const members = singleDayNumbers.members;
    const totalMembers =
        members.adult +
        members.child +
        members.concession +
        members.student +
        members.carer +
        members.additionalAdult +
        members.additionalChild +
        members.additionalConcession +
        members.additionalStudent;

    return (
        <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="flex-start"
            className={classes.gridContainer}
        >
            <Grid item xs={12} md={7} className={classes.gridItem}>
                <GSCVisitorNumbersCard
                    total={dayTotal}
                    date={date}
                    adults={singleDayNumbers.adults}
                    children={singleDayNumbers.children}
                    sales={singleDayNumbers.sales}
                    education={singleDayNumbers.education}
                    community={singleDayNumbers.community}
                    phone={singleDayNumbers.prebooked_phone}
                    online={singleDayNumbers.prebooked_online}
                    members={{...members, total: totalMembers}}
                    lastSync={singleDayNumbers.lastSync}
                    lastMemberSync={
                        singleDayNumbers.lastMemberSync ||
                        singleDayNumbers.lastSync
                    }
                    refetch={() => refetch()}
                />
            </Grid>
            {todayIsSelected && (
                <Grid item xs={12} md={5} className={classes.gridItem}>
                    <GSCVisitorChartCard
                        numbers={sortedNumbers.map((day) => {
                            return {total: day.total, date: dateify(day.date)};
                        })}
                        numberOfDataPoints={numberOfBarChartDays}
                        alwaysExpanded={false}
                        reverse={true}
                        dateStyle="day"
                        barChartMaximum={maxBarChartVisitors}
                        deepLinks={true}
                    />
                </Grid>
            )}
        </Grid>
    );
};

export default GSCNumbers;
