/** @format */

import React from "react";
import {Redirect} from "react-router-dom";
import {Grid, Typography} from "@material-ui/core";
import {GSCAuthConsumer, getAuthValues, runGSCAuth, isLoggedIn} from ".";
import GSCWrappedLoading from "../general/WrappedLoading";
import getUserImages from "./getUserImages";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        margin: theme.spacing(1, 0),
        padding: theme.spacing(1),
        [theme.breakpoints.up("md")]: {
            padding: theme.spacing(2, 4),
        },
    },
}));

const AuthLogin = ({doLogin, auto}) => {
    const classes = useStyles();
    return (
        <GSCAuthConsumer>
            {({setAuth}) => {
                if (doLogin) {
                    console.log(
                        `[${new Date().toLocaleTimeString()}]: Obtaining tokens`
                    );
                    runGSCAuth(async () => {
                        const auth = getAuthValues();
                        const pics = await getUserImages(auth);
                        setAuth({...auth, pics});
                    });
                }
                if (isLoggedIn())
                    return <Redirect to={auto ? "/" : "/auth/details"} />;
                return (
                    <Grid
                        container
                        justify="center"
                        item
                        xs={12}
                        className={classes.gridContainer}
                    >
                        <Typography variant="h4" component="h2">
                            Logging In
                        </Typography>
                        <GSCWrappedLoading />
                    </Grid>
                );
            }}
        </GSCAuthConsumer>
    );
};

export default AuthLogin;
