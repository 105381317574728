/** @format */

import React from "react";
import {Typography} from "@material-ui/core";
import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import GSCWrappedLoading from "../general/WrappedLoading";
import GammaQLDataFetchError from "../general/DataFetchError";
import OverviewTable from "./OverviewTable";
import {
    filterByStatusDisplay,
    filterByResourceDisplay,
} from "../../utils/corpEventFilters";
import {makeStyles} from "@material-ui/core/styles";
import {apiFormat} from "../../config";

const useStyles = makeStyles((theme) => ({
    emptyResultText: {
        width: "100%",
        textAlign: "center",
    },
}));

const GET_EVENTS = gql`
    query getCorporateEvents($startDate: String!, $endDate: String!) {
        corporateEvents: corporateEvents(
            startDate: $startDate
            endDate: $endDate
        ) {
            masterBookId
            eventName
            eventAttendees
            eventStart
            eventEnd
            eventManager
            bookingStatus
            resourceName
            eventType
            spaces {
                area
            }
        }
    }
`;

const OverviewList = ({start, end, statusDisplay, resourceDisplay}) => {
    const classes = useStyles();
    const {loading, error, data, refetch} = useQuery(GET_EVENTS, {
        variables: {startDate: apiFormat(start), endDate: apiFormat(end)},
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "cache-and-network",
    });

    if (loading) return <GSCWrappedLoading />;
    if (error || !Array.isArray(data.corporateEvents))
        return (
            <GammaQLDataFetchError
                theThing="Corporate Events"
                showHeader
                refetch={refetch}
            />
        );

    const displayData = data.corporateEvents
        .filter(filterByStatusDisplay(statusDisplay))
        .filter(filterByResourceDisplay(resourceDisplay));

    if (displayData.length === 0)
        return (
            <Typography variant="body1" className={classes.emptyResultText}>
                No events match the selected dates and filters.
            </Typography>
        );

    return <OverviewTable events={displayData} />;
};

export default OverviewList;
