/** @format */

import React from "react";
import {
    Card,
    Grid,
    CardContent,
    CardMedia,
    Tooltip,
    Typography,
} from "@material-ui/core";
import RefetchButton from "../general/RefetchButton";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    card: {
        borderColor: theme.gsc.navy,
        borderWidth: "1px",
        borderStyle: "solid",
    },
    cardMedia: {
        width: "48px",
        height: "48px",
    },
    mediaContainer: {
        padding: theme.spacing(1),
    },
}));

const GSCWeatherCard = ({weather, refetch}) => {
    const classes = useStyles();
    if (!weather) return null;
    return (
        <Card variant="outlined" className={classes.card}>
            <Grid
                container
                direction="row"
                justify="space-around"
                alignItems="center"
            >
                <div className={classes.mediaContainer}>
                    <Tooltip
                        placement="left"
                        arrow
                        title={
                            <Typography variant="body2">
                                {weather.day.weatherTypeDescription}
                            </Typography>
                        }
                    >
                        <CardMedia
                            image={weather.day.weatherTypeIcon}
                            className={classes.cardMedia}
                        />
                    </Tooltip>
                </div>
                <CardContent>
                    <Typography variant="body1">
                        <Tooltip
                            placement="top"
                            arrow
                            title={
                                <Typography variant="body2">
                                    Max temp (feels like)
                                </Typography>
                            }
                        >
                            <span>{weather.day.maxTempFeelsLike}</span>
                        </Tooltip>{" "}
                        |{" "}
                        <Tooltip
                            placement="bottom"
                            arrow
                            title={
                                <Typography variant="body2">
                                    Min temp (feels like)
                                </Typography>
                            }
                        >
                            <span>{weather.night.minTempFeelsLike}</span>
                        </Tooltip>{" "}
                        |{" "}
                        <Tooltip
                            placement="top"
                            arrow
                            title={
                                <Typography variant="body2">
                                    Chance of rain
                                </Typography>
                            }
                        >
                            <span>{weather.day.precipitationProbability}</span>
                        </Tooltip>{" "}
                        |{" "}
                        <Tooltip
                            placement="bottom"
                            arrow
                            title={
                                <>
                                    <Typography variant="body2">
                                        Wind speed and direction
                                    </Typography>
                                    {weather.day.windGustNoon !==
                                        weather.day.windSpeed && (
                                        <Typography variant="body2">
                                            (Gusts of {weather.day.windGustNoon}
                                            )
                                        </Typography>
                                    )}
                                </>
                            }
                        >
                            <span>
                                {weather.day.windSpeed}{" "}
                                {weather.day.windDirection}
                            </span>
                        </Tooltip>
                    </Typography>
                </CardContent>
                <RefetchButton onClick={refetch} />
            </Grid>
        </Card>
    );
};

export default GSCWeatherCard;
