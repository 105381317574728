/** @format */

import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";
import Badge from "@material-ui/core/Badge";
import Typography from "@material-ui/core/Typography";
import ConfirmedIcon from "@material-ui/icons/CheckCircle";
import ProvisionalIcon from "@material-ui/icons/Help";
import CancelledIcon from "@material-ui/icons/Cancel";
import {makeStyles} from "@material-ui/core/styles";
import {getCorpEventStatusCategory} from "../../config";

const useStyles = makeStyles((theme) => ({
    statusAvatar: ({statusCategory, padded, justify}) => ({
        color:
            statusCategory === "confirmed"
                ? theme.gsc.green
                : statusCategory === "cancelled"
                ? theme.gsc.red
                : statusCategory === "provisional"
                ? theme.gsc.orange
                : theme.palette.grey[700],
        backgroundColor: "transparent",
        marginRight: padded ? theme.spacing(1) : 0,
        justifyContent: justify || "center",
    }),
    badge: {
        backgroundColor: theme.gsc.purple,
    },
}));

const StatusAvatar = ({status, internal, padded, justify, tooltip}) => {
    const statusCategory = getCorpEventStatusCategory(status);
    const classes = useStyles({statusCategory, padded, justify});
    const icon =
        statusCategory === "confirmed" ? (
            <ConfirmedIcon />
        ) : statusCategory === "cancelled" ? (
            <CancelledIcon />
        ) : (
            <ProvisionalIcon />
        );

    return tooltip ? (
        <Tooltip
            arrow
            title={
                <>
                    <Typography variant="body2">{status}</Typography>
                    {internal && (
                        <Typography variant="body2">
                            (GSC Internal Event)
                        </Typography>
                    )}
                </>
            }
        >
            {internal ? (
                <Badge
                    classes={{badge: classes.badge}}
                    overlap="circle"
                    variant="dot"
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                >
                    <Avatar className={classes.statusAvatar}>{icon}</Avatar>
                </Badge>
            ) : (
                <Avatar className={classes.statusAvatar}>{icon}</Avatar>
            )}
        </Tooltip>
    ) : (
        <Avatar className={classes.statusAvatar}>{icon}</Avatar>
    );
};

export default StatusAvatar;
