/** @format */

import React from "react";
import {Grid, Typography} from "@material-ui/core";
import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import GSCWrappedLoading from "../../general/WrappedLoading";
import GammaQLDataFetchError from "../../general/DataFetchError";
import GSCDateHeader from "../../general/DateHeader";
import PrintRenderer from "./PrintRenderer";
import {sortByEventStartTimeAsc} from "../../../utils/corpEventFilters";

const GET_EVENTS = gql`
    query getCorporateEvent($bookingReferenceList: [String!]) {
        corporateEvents: corporateEvents(
            bookingReferenceList: $bookingReferenceList
        ) {
            masterBookId
            eventName
            eventAttendees
            eventStart
            eventEnd
            eventManager
            opsManager
            mainContactOnDay
            bookingStatus
            resourceName
            bookingCategory
            eventType
            eventNotes {
                general
                timetable
                staffing
                setup
                av
                facilities
                catering
                beverage
                cleaning
                internal
                additional
                billing
            }
            spaces {
                linkedBookId
                spaceName
                spaceType
                spaceAttendees
                spaceStart
                spaceEnd
                area
                roomStyle
                spaceNotes {
                    general
                    timetable
                    staffing
                    setup
                    av
                    facilities
                    catering
                    beverage
                    cleaning
                    internal
                    additional
                    billing
                }
            }
            lastSync
        }
    }
`;

const PrintCorporateEvents = ({events}) => {
    const bookingReferenceList = events
        ? events.split(",").map((bookingRef) => bookingRef.trim())
        : undefined;

    const {loading, error, data, refetch} = useQuery(GET_EVENTS, {
        variables: {bookingReferenceList},
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "cache-and-network",
    });

    if (loading)
        return (
            <GSCWrappedLoading heading={true}>
                <Typography variant="h5" component="h4">
                    Corporate Events
                </Typography>
            </GSCWrappedLoading>
        );
    if (error || !Array.isArray(data.corporateEvents))
        return (
            <GammaQLDataFetchError
                theThing="Corporate Events"
                showHeader
                refetch={refetch}
            />
        );

    if (
        bookingReferenceList &&
        data.corporateEvents.length !== bookingReferenceList.length
    )
        return (
            <Grid container direction="column" justify="center">
                <GSCDateHeader>Corporate Events</GSCDateHeader>
                <Grid item xs={12} md={10}>
                    <Typography variant="body1">
                        No event details are available for one or more of the
                        requested booking references
                    </Typography>
                </Grid>
            </Grid>
        );

    const eventsData = data.corporateEvents.sort(sortByEventStartTimeAsc);
    return <PrintRenderer eventsData={eventsData} />;
};

export default PrintCorporateEvents;
