/** @format */

import setupGSCAuth from "@gsc/react-auth";
import {aadAppId, authRedirectUri, msGraphScopes} from "../../config";

const {
    GSCAuthProvider,
    GSCAuthConsumer,
    initialAuthValues,
    getAuthValues,
    isLoggedIn,
    runGSCAuth,
    requireGSCAuth,
    clearGSCAuth,
} = setupGSCAuth(aadAppId, authRedirectUri, msGraphScopes);

export {
    GSCAuthProvider,
    GSCAuthConsumer,
    initialAuthValues,
    getAuthValues,
    isLoggedIn,
    runGSCAuth,
    requireGSCAuth,
    clearGSCAuth,
};
