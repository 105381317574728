/** @format */

import React from "react";
import {IconButton} from "@material-ui/core";
import ReplayIcon from "@material-ui/icons/Replay";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    iconButton: ({offsetPadding}) => ({
        padding: theme.spacing(1),
        marginRight: theme.spacing(offsetPadding ? -1 : 0),
    }),
}));

const RefetchButton = ({onClick, offsetPadding = false} = {}) => {
    const classes = useStyles({offsetPadding});
    return (
        <IconButton onClick={onClick} className={classes.iconButton}>
            <ReplayIcon />
        </IconButton>
    );
};

export default RefetchButton;
