/** @format */

import React from "react";
import {Container, Grid, Typography, Button} from "@material-ui/core";
import GSCDayParams from "../dayparameters/";
import GSCNumbers from "../visitornumbers/";
import GSCEvents from "../events/";
import GSCDateHeader from "../general/DateHeader";
import {useSelector, useDispatch} from "react-redux";
import {dateSelector} from "../../redux/selectedDateSlice";
import {push} from "connected-react-router";
import useAutoAdvance from "../../utils/useAutoAdvance";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        margin: theme.spacing(3),
    },
}));

const SingleDate = () => {
    const classes = useStyles();
    const date = useSelector(dateSelector);
    const dispatch = useDispatch();
    useAutoAdvance();
    if (!date)
        return (
            <GSCDateHeader>
                <Grid
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="flex-start"
                    className={classes.gridContainer}
                >
                    <Typography variant="h4" component="h2">
                        Error
                    </Typography>
                    <Typography variant="body1">
                        No valid date specified
                    </Typography>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => dispatch(push("/date/today"))}
                    >
                        Go to Today
                    </Button>
                </Grid>
            </GSCDateHeader>
        );
    return (
        <Container maxWidth="xl">
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
            >
                <GSCDayParams date={date} />
                <GSCNumbers date={date} />
                <GSCEvents date={date} />
            </Grid>
        </Container>
    );
};

export default SingleDate;
