/** @format */

import React from "react";
import {Text, View, StyleSheet} from "@react-pdf/renderer";
import HorizontalRule from "./HorizontalRule";
import {format, isSameDay} from "date-fns";
import {
    getAllSpaceNoteTypes,
    capitalFirst,
} from "../../../utils/corpEventFilters";
import {notesExcludedFromExternalEvents} from "../../../config";

const styles = StyleSheet.create({
    section: {
        margin: "2 6",
        padding: 5,
        border: "10px solid #2e2d44",
    },
    columnContainer: {
        margin: 5,
        flexDirection: "column",
        justifyContent: "space-between",
    },
    columnContainerZero: {
        marginTop: 5,
        marginBottom: 5,
        flexDirection: "column",
        justifyContent: "space-between",
    },
    heading: {
        fontFamily: "ProximaNova",
        color: "#2e2d44",
        margin: "3 0",
    },
    h1size: {
        fontSize: "24pt",
    },
    h2size: {
        fontSize: "20pt",
    },
    h3size: {
        fontSize: "16pt",
    },
    bodyText: {
        margin: 1,
        fontFamily: "Arial",
        fontSize: "10pt",
    },
    bold: {
        fontWeight: "bold",
    },
});

const SingleNoteType = ({noteType, spaces}) => (
    <View style={styles.columnContainerZero}>
        <Text style={[styles.heading, styles.h2size]} minPresenceAhead={50}>
            {capitalFirst(noteType)} Notes
        </Text>
        {spaces.map((space) => {
            // Line breaks need to be just \n and not \r\n, otherwise \r is rendered as a "missing character" square
            const note = space.spaceNotes[noteType].replace(/\r\n/gi, "\n");
            const titular = (text, separator) => {
                const trimmedText = String(text).trim();
                return `${trimmedText}${
                    Boolean(trimmedText) ? ` ${separator} ` : ""
                }`;
            };
            const times = `${format(
                Number(space.spaceStart),
                "EEE do, HH:mm"
            )}–${format(
                Number(space.spaceEnd),
                isSameDay(Number(space.spaceStart), Number(space.spaceEnd))
                    ? "HH:mm"
                    : "EEE do, HH:mm"
            )}`;
            return (
                Boolean(space.spaceNotes[noteType].trim()) && (
                    <View
                        key={space.linkedBookId}
                        style={styles.columnContainer}
                    >
                        <Text
                            style={[styles.heading, styles.h3size]}
                            minPresenceAhead={30}
                        >
                            {`${titular(space.spaceName, "|")}${titular(
                                space.spaceType,
                                "|"
                            )}${titular(times, "|")}${titular(
                                `${space.spaceAttendees} people`,
                                "|"
                            )}${titular(space.area, "")}`}
                        </Text>
                        <Text style={styles.bodyText}>{note}</Text>
                    </View>
                )
            );
        })}
    </View>
);

const SpaceNotes = ({spaces, internal, includedNoteTypes}) => {
    const noteTypes = getAllSpaceNoteTypes(spaces).filter(
        (type) => includedNoteTypes[type]
    );
    const displayTypes = internal
        ? noteTypes
        : noteTypes.filter(
              (type) => !notesExcludedFromExternalEvents.includes(type)
          );
    return (
        Boolean(displayTypes.length) && (
            <View style={styles.section}>
                {displayTypes.map((noteType, idx) => (
                    <React.Fragment key={noteType}>
                        <SingleNoteType noteType={noteType} spaces={spaces} />
                        {idx !== noteTypes.length - 1 && <HorizontalRule />}
                    </React.Fragment>
                ))}
            </View>
        )
    );
};

export default SpaceNotes;
